export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

export const convertFilesToBase64 = async (data: any): Promise<any> => {
  if (!data) return data;
  
  if (data instanceof File) {
    return {
      name: data.name,
      type: data.type,
      size: data.size,
      data: await fileToBase64(data)
    };
  }

  if (Array.isArray(data)) {
    return Promise.all(data.map(item => convertFilesToBase64(item)));
  }

  if (typeof data === 'object') {
    const result: Record<string, any> = {};
    for (const [key, value] of Object.entries(data)) {
      result[key] = await convertFilesToBase64(value);
    }
    return result;
  }

  return data;
}; 