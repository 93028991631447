import React from 'react';
import { motion } from 'framer-motion';

interface QuestionProps {
  question: string;
  subtext?: string;
  children: React.ReactNode;
}

export const Question: React.FC<QuestionProps> = ({ 
  question, 
  subtext, 
  children 
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="max-w-2xl mx-auto"
  >
    <h3 className="text-3xl font-light text-gray-900 mb-3">
      {question}
    </h3>
    {subtext && (
      <p className="text-gray-500 text-lg mb-8">
        {subtext}
      </p>
    )}
    <div className="mt-8">
      {children}
    </div>
  </motion.div>
);

export default Question; 