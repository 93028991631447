import React, { useState, useEffect } from 'react';
import { message, Button } from 'antd';
import { motion } from 'framer-motion';
import { getBlogIdeas } from '../../functions';
import { WebsiteGeneratorBusinessInfo, BlogIdea } from '../types/websiteGenerator';


interface BlogIdeaGeneratorProps {
  businessInfo: WebsiteGeneratorBusinessInfo;
  keywords: string[];
  loading: boolean;
  onChange: (ideas: string[]) => void;
}

export const BlogIdeaGenerator: React.FC<BlogIdeaGeneratorProps> = ({
  businessInfo,
  keywords,
  loading,
  onChange,
}) => {
  const [ideas, setIdeas] = useState<BlogIdea[]>([]);
  const [generating, setGenerating] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Analyzing your industry...');

  const messages = [
    'Analyzing your industry...',
    'Researching trending topics...',
    'Generating relevant ideas...',
    'Optimizing for your keywords...',
    'Almost there...'
  ];

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (generating) {
      let i = 0;
      interval = setInterval(() => {
        setLoadingMessage(messages[i % messages.length]);
        i++;
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [generating]);

  useEffect(() => {
    generateIdeas();
  }, []);

  const generateIdeas = async () => {
    setGenerating(true);
    try {
      const blogIdeas = await getBlogIdeas(businessInfo, keywords);
      console.log('Generated blog ideas:', blogIdeas);
      
      const formattedIdeas = Array.isArray(blogIdeas) 
        ? blogIdeas.map(idea => ({
            title: typeof idea === 'string' ? idea : idea.title,
            summary: typeof idea === 'string' ? '' : idea.summary,
            score: typeof idea === 'string' ? 0 : (idea.score || 0)
          }))
        : [];
      
      setIdeas(formattedIdeas);
      onChange(formattedIdeas.map(idea => idea.title));
    } catch (error) {
      console.error('Error generating blog ideas:', error);
      message.error('Failed to generate blog ideas');
      setIdeas([]);
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div className="space-y-6">
      {generating ? (
        <div className="w-full h-[500px] flex flex-col items-center justify-center bg-gradient-to-b from-white to-blue-50/30">
          <div className="relative w-24 h-24 mb-8">
            <motion.div 
              className="absolute inset-0 border-4 border-t-blue-500 border-r-blue-300 
                        border-b-blue-200 border-l-blue-100 rounded-full"
              animate={{ rotate: 360 }}
              transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            />
            <motion.div 
              className="absolute inset-2 border-4 border-t-blue-400 border-r-blue-200 
                        border-b-blue-100 border-l-transparent rounded-full"
              animate={{ rotate: -360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            />
          </div>

          <motion.div 
            className="text-center"
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
          >
            <h3 className="text-2xl font-medium text-blue-600">
              Generating blog ideas for your business...
            </h3>
            <p className="text-gray-600 mt-2">
              {loadingMessage}
            </p>
          </motion.div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="max-h-[400px] overflow-y-auto pr-4 space-y-4">
            {ideas.map((idea, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className="p-4 border-2 rounded-xl bg-white hover:border-blue-500 transition-all"
              >
                <h4 className="font-medium">{idea.title}</h4>
                <p className="text-gray-600 text-sm mt-2">{idea.summary}</p>
              </motion.div>
            ))}
          </div>
          <Button
            onClick={generateIdeas}
            className="w-full py-4 h-auto text-lg"
          >
            Generate More Blog Ideas
          </Button>
        </div>
      )}
    </div>
  );
};

export default BlogIdeaGenerator; 