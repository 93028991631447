'use client'
import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { MdClose } from 'react-icons/md';

export interface GalleryImage {
  file: File;
  description: string;
}

interface GalleryUploadProps {
  images: GalleryImage[];
  onChange: (images: GalleryImage[]) => void;
}

const GalleryUpload: React.FC<GalleryUploadProps> = ({ images, onChange }) => {
  const [imagesState, setImagesState] = useState<GalleryImage[]>(images);

  const handleImagesChange = (newImages: GalleryImage[]) => {
    setImagesState(newImages);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const newImages = files.map(file => ({
      file,
      description: ''
    }));
    const updatedImages = [...imagesState, ...newImages];
    setImagesState(updatedImages);
    onChange(updatedImages);
  };


  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        {imagesState.map((image, index) => (
          <div key={index} className="relative p-4 border-2 border-gray-200 rounded-xl">
            <img
              src={URL.createObjectURL(image.file)}
              alt={`Upload ${index + 1}`}
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            
            <Input.TextArea
              placeholder="Add a description..."
              value={image.description}
              onChange={(e) => {
                const updatedImages = [...imagesState];
                updatedImages[index].description = e.target.value;
                setImagesState(updatedImages);
                onChange(updatedImages);
              }}
              className="w-full"
              rows={2}
            />
            
            <button
              onClick={() => {
                const updatedImages = imagesState.filter((_, i) => i !== index);
                setImagesState(updatedImages);
                onChange(updatedImages);
              }}
              className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md
                         text-red-500 hover:text-red-600"
            >
              <MdClose size={20} />
            </button>
          </div>
        ))}
      </div>
      
      <Button
        onClick={() => document.getElementById('gallery-upload')?.click()}
        className="w-full p-6 border-2 border-dashed"
      >
        Upload Images
      </Button>
      <input
        id="gallery-upload"
        type="file"
        accept="image/*"
        multiple
        className="hidden"
        onChange={handleUpload}
      />
      
    </div>
    
  );
};

export default GalleryUpload; 