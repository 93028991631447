import React from 'react';
import { Input } from 'antd';

interface ContactInfo {
  email: string;
  phone: string;
  address: string;
}

interface ContactSectionProps {
  contactInfo: ContactInfo;
  onChange: (field: keyof ContactInfo, value: string) => void;
}

const ContactSection = ({ contactInfo, onChange }: ContactSectionProps) => {
  const handleContactInfoChange = (field: keyof ContactInfo, value: string) => {
    console.log('Contact info changing:', { field, value });
    onChange(field, value);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Email Address <span className="text-red-500">*</span>
        </label>
        <Input
          type="email"
          value={contactInfo.email}
          onChange={(e) => handleContactInfoChange('email', e.target.value)}
          placeholder="contact@yourbusiness.com"
          className="text-lg p-4 rounded-xl border-2"
        />
      </div>

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Phone Number <span className="text-red-500">*</span>
        </label>
        <Input
          type="tel"
          value={contactInfo.phone}
          onChange={(e) => handleContactInfoChange('phone', e.target.value)}
          placeholder="(555) 123-4567"
          className="text-lg p-4 rounded-xl border-2"
        />
      </div>

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Business Address
        </label>
        <Input.TextArea
          value={contactInfo.address}
          onChange={(e) => handleContactInfoChange('address', e.target.value)}
          placeholder="Your business location"
          className="text-lg p-4 rounded-xl border-2"
          rows={3}
        />
      </div>
    </div>
  );
};

export type { ContactInfo };
export default ContactSection; 