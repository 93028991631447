import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  APIProvider,
  ControlPosition,
  Map,
  useMap,
  MapControl,
  useMapsLibrary
} from '@vis.gl/react-google-maps';
import { Location } from '../types/websiteGenerator';

const API_KEY = "AIzaSyDlItrk1-n0F7L2_usB4Kg9SsKG8wE5Wrg";

// Add CircleOverlay component
const CircleOverlay = ({ center, radius }: { center: google.maps.LatLngLiteral, radius: number }) => {
  const map = useMap();
  
  useEffect(() => {
    if (!map) return;

    const circle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center,
      radius,
    });

    return () => {
      circle.setMap(null);
    };
  }, [map, center, radius]);

  return null;
};

const SearchControl = ({ onPlaceSelect }: { 
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void 
}) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <MapControl position={ControlPosition.TOP}>
      <div className="m-4 w-96">
        <div className="flex gap-2">
          <div className="relative flex-1">
            <PlaceAutocomplete 
              onPlaceSelect={onPlaceSelect}
              inputRef={searchInputRef}
            />
          </div>
        </div>
      </div>
    </MapControl>
  );
};

const MapHandler = ({ place }: { place: google.maps.places.PlaceResult | null }) => {
  const map = useMap();

  React.useEffect(() => {
    if (!map || !place?.geometry) return;

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
      // After fitting to viewport, zoom out slightly
      setTimeout(() => {
        const currentZoom = map.getZoom();
        if (currentZoom) {
          map.setZoom(currentZoom - 1);
        }
      }, 100);
    } else if (place.geometry.location) {
      map.setCenter(place.geometry.location);
      map.setZoom(13); // More zoomed out than before (was 15)
    }
  }, [map, place]);

  return null;
};

// Add Marker component
const Marker = ({ position }: { position: google.maps.LatLngLiteral }) => {
  const map = useMap();
  
  useEffect(() => {
    if (!map) return;

    const marker = new google.maps.Marker({
      position,
      map,
    });

    return () => {
      marker.setMap(null);
    };
  }, [map, position]);

  return null;
};

interface LocationStepProps {
  onChange: (locations: Location[]) => void;
}

const LocationStep: React.FC<LocationStepProps> = ({ onChange }) => {
  const [locations, setLocations] = useState<Array<{
    placeName: string,
    coordinates: { lat: number, lng: number },
    radius: number,
    locationType: 'state' | 'country' | 'city' | 'address' | 'point'
  }>>([]);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  // Update parent with locations changes
  const handleLocationsChange = useCallback((newLocations: typeof locations) => {
    setLocations(newLocations);
    onChange(newLocations.map(loc => ({
      address: loc.placeName,
      latitude: loc.coordinates.lat,
      longitude: loc.coordinates.lng,
      radius: loc.radius,
      locationType: loc.locationType
    })));
  }, [onChange]);

  const handlePlaceSelect = (place: google.maps.places.PlaceResult | null) => {
    if (!place?.geometry?.location) {
      console.warn('No geometry location in place:', place);
      return;
    }

    // Determine location type by checking place.types array
    let locationType: 'state' | 'country' | 'city' | 'address' | 'point';
    
    if (place.types?.includes('administrative_area_level_1')) {
      locationType = 'state';
    } else if (place.types?.includes('country')) {
      locationType = 'country';
    } else if (place.types?.includes('locality')) {
      locationType = 'city';
    } else if (place.types?.includes('route')) {
      locationType = 'address';
    } else {
      locationType = 'point';
    }
    
    const newLocation = {
      placeName: place.formatted_address || place.name || '',
      coordinates: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      },
      radius: 1609.34, // Default to 1 mile (in meters)
      locationType
    };
    
    handleLocationsChange([...locations, newLocation]);
    setSelectedPlace(place);

    // Clear the search input
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  return (
    <div className="w-full space-y-8">
      <APIProvider apiKey={API_KEY} libraries={['places']}>
        {/* Search bar above map */}
        <div className="relative max-w-2xl mx-auto">
          <PlaceAutocomplete 
            onPlaceSelect={handlePlaceSelect}
            inputRef={searchInputRef}
          />
        </div>

        {/* Map */}
        <div className="h-[400px] w-full overflow-hidden shadow-lg">
          <Map
            mapId={'bf51a910020fa25a'}
            defaultZoom={2}
            defaultCenter={{ lat: 40, lng: 0 }}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            className="w-full h-full"
          >
            {locations.map((loc, index) => (
              loc.locationType === 'state' || loc.locationType === 'country' ? (
                <Marker
                  key={index}
                  position={loc.coordinates}
                />
              ) : (
                <CircleOverlay
                  key={index}
                  center={loc.coordinates}
                  radius={loc.radius}
                />
              )
            ))}
            <MapHandler place={selectedPlace} />
          </Map>
        </div>

        {locations.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
            {locations.map((loc, index) => (
              <div 
                key={index} 
                className="bg-white p-6 shadow-[0_2px_8px_rgb(0,0,0,0.06)] 
                         hover:shadow-[0_4px_16px_rgb(0,0,0,0.1)] transition-all duration-300"
              >
                <div className="flex justify-between items-start mb-4">
                  <h3 className="font-medium text-gray-900 text-lg leading-tight">{loc.placeName}</h3>
                  <button
                    onClick={() => handleLocationsChange(locations.filter((_, i) => i !== index))}
                    className="text-gray-400 hover:text-red-500 transition-colors"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                
                <div className="space-y-4">
                  <div className="flex items-center text-sm text-gray-500 font-mono bg-gray-50 p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                    {loc.coordinates.lat.toFixed(6)}, {loc.coordinates.lng.toFixed(6)}
                  </div>

                  {/* Only show radius control for non-state and non-country locations */}
                  {loc.locationType !== 'state' && loc.locationType !== 'country' && (
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-sm font-medium text-gray-600">Service Area</span>
                        <span className="text-sm font-medium text-blue-600">
                          {Math.round(loc.radius / 1609.34)} mile
                        </span>
                      </div>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={Math.round(loc.radius / 1609.34)}
                        onChange={(e) => {
                          const radiusMiles = parseInt(e.target.value);
                          handleLocationsChange(locations.map((l, i) => 
                            i === index ? {
                              ...l,
                              radius: radiusMiles * 1609.34
                            } : l
                          ));
                        }}
                        className="w-full h-1.5 bg-gray-100 appearance-none cursor-pointer 
                                 accent-blue-500"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </APIProvider>
    </div>
  );
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const PlaceAutocomplete = ({ onPlaceSelect, inputRef }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address', 'types'],
      types: ['establishment', 'geocode']
    };

    const autocomplete = new places.Autocomplete(inputRef.current, options);
    setPlaceAutocomplete(autocomplete);

    const listener = autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      console.log('Place changed:', place);
      console.log('Place types:', place.types);
      
      if (!place.geometry?.location) {
        console.warn('Place contains no geometry');
        return;
      }
      onPlaceSelect(place);
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [places, onPlaceSelect, inputRef]);

  return (
    <div className="w-full">
      <input
        ref={inputRef}
        className="w-full p-4 bg-gray-50 focus:bg-white
                   shadow-[0_1px_8px_rgb(0,0,0,0.05)] 
                   outline-none ring-0 border-0
                   placeholder:text-gray-400 text-gray-700
                   transition-all duration-200"
        placeholder="Search for your business location..."
      />
    </div>
  );
};

// const root = createRoot(document.getElementById('app')!);
// root.render(<LocationStep />);

export default LocationStep;
