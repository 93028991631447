import React, { useState } from 'react';
import { Input } from 'antd';
import { MdClose } from 'react-icons/md';

interface TeamMember {
  id: string;
  name: string;
  title: string;
  image?: File;
  bio?: string;
}

interface AboutSectionProps {
  story: string;
  teamMembers: TeamMember[];
  onChange: (field: 'story' | 'team', value: string | TeamMember[]) => void;
}

const TeamMemberInput = ({ members, onChange }: {
  members: TeamMember[];
  onChange: (members: TeamMember[]) => void;
}) => {
  const addMember = () => {
    const newMember = {
      id: `member-${Date.now()}`,
      name: '',
      title: '',
    };
    onChange([...members, newMember]);
  };

  const updateMember = (id: string, field: keyof TeamMember, value: string | File | null) => {
    const updatedMembers = members.map(member => 
      member.id === id ? { 
        ...member, 
        [field]: field === 'image' && value === null ? undefined : value 
      } : member
    );
    onChange(updatedMembers);
  };

  const removeMember = (id: string) => {
    onChange(members.filter(member => member.id !== id));
  };

  return (
    <div className="space-y-6">
      {members.map((member) => (
        <div key={member.id} className="p-6 border-2 border-gray-200 rounded-xl relative">
          <button
            type="button"
            onClick={() => removeMember(member.id)}
            className="absolute top-4 right-4 p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50"
          >
            <MdClose size={20} />
          </button>

          <div className="grid grid-cols-[120px,1fr] gap-6">
            {/* Image Upload */}
            <div className="space-y-2">
              {member.image ? (
                <div className="relative">
                  <img
                    src={URL.createObjectURL(member.image)}
                    alt={member.name}
                    className="w-28 h-28 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => updateMember(member.id, 'image', null)}
                    className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md text-red-500 hover:text-red-600"
                  >
                    <MdClose size={16} />
                  </button>
                </div>
              ) : (
                <label className="flex flex-col items-center justify-center w-28 h-28 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:border-blue-500 hover:bg-gray-50">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <span className="text-2xl mb-2">+</span>
                    <span className="text-xs text-gray-500">Photo</span>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) updateMember(member.id, 'image', file);
                    }}
                  />
                </label>
              )}
            </div>

            {/* Member Details */}
            <div className="space-y-4">
              <Input
                value={member.name}
                onChange={(e) => updateMember(member.id, 'name', e.target.value)}
                placeholder="Name"
                className="text-lg p-4 rounded-xl border-2"
              />
              
              <Input
                value={member.title}
                onChange={(e) => updateMember(member.id, 'title', e.target.value)}
                placeholder="Title / Role"
                className="text-lg p-4 rounded-xl border-2"
              />

              <Input.TextArea
                value={member.bio || ''}
                onChange={(e) => updateMember(member.id, 'bio', e.target.value)}
                placeholder="Bio (optional)"
                className="text-lg p-4 rounded-xl border-2"
                rows={3}
              />
            </div>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={addMember}
        className="w-full p-6 border-2 border-dashed border-gray-300 rounded-xl text-gray-500 hover:text-blue-500 hover:border-blue-500 transition-all flex items-center justify-center space-x-2"
      >
        <span className="text-2xl">+</span>
        <span>Add Team Member</span>
      </button>
    </div>
  );
};

const AboutSection = ({ story, teamMembers: initialTeamMembers = [], onChange }: AboutSectionProps) => {
  const [businessStory, setBusinessStory] = useState(story || '');
  const [teamMembers, setTeamMembers] = useState(initialTeamMembers);

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Business Story
        </label>
        <Input.TextArea
          value={businessStory}
          onChange={(e) => {
            setBusinessStory(e.target.value);
            onChange('story', e.target.value);
          }}
          placeholder="Share your business story, mission, and values"
          className="text-lg p-4 rounded-xl border-2"
          rows={6}
          maxLength={2000}
          showCount
        />
      </div>

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Team Members
        </label>
        <TeamMemberInput
          members={teamMembers}
          onChange={(members) => {
            setTeamMembers(members);
            onChange('team', members);
          }}
        />
      </div>
    </div>
  );
};

export type { TeamMember };
export default AboutSection; 