'use client'
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Input, message, Modal, Spin, Tag, Steps, Tooltip, Select, Switch, Upload, ColorPicker } from 'antd';
import SvgsIcon from '../../components/svg-icon';
import { getKeywordIdeas, getBlogIdeas, uploadMediaLibraryItem, generateWebsiteBlueprint, checkWebsiteBlueprintStatus } from '../../functions';
import { MdClose } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';
import { InfoCircleOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { string } from 'zod';
import GalleryUpload, { GalleryImage } from './GalleryUpload';
import BookingSetup from './BookingSetup';
import AboutSection, { TeamMember } from './AboutSection';
import ContactSection, { ContactInfo } from './ContactSection';
import BrandingSection, { BrandingInfo } from './BrandingSection';
import OfferingInput from './OfferingInput';
import BlogIdeaGenerator from './BlogIdeaGenerator';
import OfferingManager from './OfferingManager';
import Question from './Question';
import {
  WebsiteGeneratorBusinessInfo,
  PageTemplate,
  PageSpecificInfo,
  BookingSettings,
  Keyword,
  BlogIdea
} from '../types/websiteGenerator';
import { fileToBase64, convertFilesToBase64 } from '../utils/fileUtils';
import { getRelevantPages } from '../utils/pageGeneratorUtils';
import LocationStep from './LocationStep';

interface WebsiteGeneratorWalkthroughProps {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  hasPendingWebsite?: boolean;
  businessEntity?: {
    name?: string;
    industry?: string;
    description?: string;
    type?: 'products' | 'services' | 'both';
    offerings?: WebsiteGeneratorBusinessInfo['offerings'];
    company_description?: string;
    company_email?: string;
    company_locations?: Array<any>;
    company_name?: string;
    company_website?: string;
    marketing_channels?: string[];
    active_channels?: string[];
    target_demographics?: string[];
    target_interests?: string[];
    target_pain_points?: string[];
  };
}

interface GeneratePageModalProps {
  visible: boolean;
  onClose: () => void;
  onGenerate: (instruction: string) => Promise<void>;
  pageName: string;
  loading: boolean;
  globalBrandingType: string;
  globalBrandInfo: any;
}

// Define the industries array
const INDUSTRIES = [
  { value: 'insurance', label: 'Insurance' },
  { value: 'signage', label: 'Signage' },
  { value: 'salon', label: 'Salon' },
  { value: 'real-estate-investment-firms', label: 'Real Estate Investment Firms' },
  { value: 'freight', label: 'Freight' },
  { value: 'gyms', label: 'Gyms' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'auto-collision', label: 'Auto Collision' },
  { value: 'residential-services', label: 'Residential Services' }
];

// Update getPageSpecificQuestions
const getPageSpecificQuestions = (page: PageTemplate, businessInfo: WebsiteGeneratorBusinessInfo) => {
  switch (page.id) {
    case 'about':
      return {
        question: "About Your Business",
        subtext: "Help customers get to know your business better",
        fields: [
          {
            key: 'about',
            type: 'about-section',
            label: "About Section",
            placeholder: "",
            required: false
          }
        ]
      };

    case 'contact':
      return {
        question: "Contact Information",
        subtext: "Add your business contact details",
        fields: [
          {
            key: 'contact',
            type: 'contact-section',
            label: "Contact Information",
            placeholder: "",
            required: true
          }
        ]
      };

    case 'gallery':
      return {
        question: "Add photos to your website",
        subtext: "Upload images with descriptions to showcase your work",
        fields: [
          {
            key: 'gallery',
            type: 'gallery',
            label: "",
            placeholder: "",
            required: false
          }
        ]
      };

    case 'blog':
      return {
        question: "Let's create engaging blog content for your website",
        subtext: "We'll analyze your business and generate relevant blog post ideas",
        fields: [
          {
            key: 'blogIdeas',
            type: 'blog',
            label: "",
            placeholder: "",
            required: false
          }
        ]
      };

    case 'booking':
      return {
        question: "Setup Online Booking",
        subtext: "Configure your online booking system",
        fields: [
          {
            key: 'booking',
            type: 'booking',
            label: "Booking Settings",
            placeholder: "",
            required: true
          }
        ]
      };

    case 'branding':
      return {
        question: "Let's set up your website's branding",
        subtext: "Choose between using a reference website or custom branding",
        fields: [
          {
            key: 'branding',
            type: 'branding-section',
            label: "Branding",
            placeholder: "",
            required: true
          }
        ]
      };

    default:
      return null;
  }
};

// Add this component for sophisticated service/product management

const MESSAGES = [
  "Finding the perfect keywords for your website...",
  "Analyzing your industry competitors...",
  "Discovering high-impact search terms...",
  "Optimizing keyword selection...",
  "Almost ready with your results..."
] as const;

// Add this new component at the top of the file after imports
const GeneratingView: React.FC = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="min-h-[60vh] flex flex-col items-center justify-center text-center px-8"
  >
    <motion.div 
      className="w-24 h-24 mb-8 relative"
      animate={{ rotate: 360 }}
      transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
    >
      <div className="absolute inset-0 border-4 border-t-blue-500 border-r-blue-300 
                    border-b-blue-200 border-l-blue-100 rounded-full" />
      <div className="absolute inset-2 border-4 border-t-blue-400 border-r-blue-200 
                    border-b-blue-100 border-l-transparent rounded-full" />
    </motion.div>

    <h2 className="text-3xl font-medium text-blue-600 mb-4">
      Your Website Is Being Generated
    </h2>
    
    <p className="text-gray-600 text-lg mb-8 max-w-md">
      We're creating your custom website based on all the information you've provided. 
      This process may take a few minutes.
    </p>

    <div className="bg-blue-50 p-6 rounded-xl max-w-md">
      <h3 className="font-medium text-blue-800 mb-2">What happens next?</h3>
      <p className="text-blue-600">
        You can close this window and check back later. We'll notify you when your website is ready!
      </p>
    </div>
  </motion.div>
);

const WebsiteGeneratorWalkthrough: React.FC<WebsiteGeneratorWalkthroughProps> = ({
  visible,
  onClose,
  loading,
  hasPendingWebsite = false,
  businessEntity
}) => {
  // console.log('WebsiteGeneratorWalkthrough businessEntity:', businessEntity);

  // Add these with your other state declarations
  const [keywords, setKeywords] = useState<{[key: string]: Keyword}>({});
  const [loadingKeywords, setLoadingKeywords] = useState<string>("false");
  const [msgIndex, setMsgIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState<string>(MESSAGES[0]);

  // Add this for blog ideas
  const [blogIdeas, setBlogIdeas] = useState<Array<{
    title: string;
    summary: string;
    score?: number;
  }>>([]);
  const [loadingBlogIdeas, setLoadingBlogIdeas] = useState<string>("false");

  // Your existing state declarations
  const [currentStep, setCurrentStep] = useState(0);
  const [businessInfo, setBusinessInfo] = useState<WebsiteGeneratorBusinessInfo>(() => ({
    name: businessEntity?.name || '',
    industry: businessEntity?.industry || '',
    description: businessEntity?.description || '',
    type: businessEntity?.type || 'services',
    offerings: businessEntity?.offerings || [],
    competitors: [],
    locations: [],
  }));

  // Add this state variable to store the gallery images
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);

  const [selectedPages, setSelectedPages] = useState<PageTemplate[]>([
    { id: 'home', name: 'Home', description: 'Main landing page', selected: true },
    { id: 'about', name: 'About', description: 'Company information and story', selected: false },
    { id: 'services', name: 'Services', description: 'List of services offered', selected: false },
    { id: 'products', name: 'Products', description: 'Product catalog', selected: false },
    { id: 'contact', name: 'Contact', description: 'Contact information', selected: false },
    { id: 'gallery', name: 'Gallery', description: 'Image gallery', selected: false },
    { id: 'blog', name: 'Blog', description: 'News and updates', selected: false },
    { id: 'faq', name: 'FAQ', description: 'Frequently asked questions', selected: false },
    { id: 'testimonials', name: 'Testimonials', description: 'Customer reviews', selected: false }
  ]);

  const [pageSpecificInfo, setPageSpecificInfo] = useState<PageSpecificInfo>({
    about: {
      story: '',
      team: []
    }
  });

  // Update location state
  const [location, setLocation] = useState({
    placeName: '',
    radius: 25,
    coordinates: undefined
  });

  const [brandingType, setBrandingType] = useState<'reference' | 'custom'>('custom');
  const [referenceUrl, setReferenceUrl] = useState('');
  const [logo, setLogo] = useState<File | null>(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColors, setSecondaryColors] = useState<string[]>(['#000000']);

  // Add this new state near your other state declarations
  const [isGenerating, setIsGenerating] = useState(false);

  // Add an error state
  const [keywordError, setKeywordError] = useState(false);

  useEffect(() => {
    console.log('useEffect businessEntity:', businessEntity);
    if (businessEntity && !businessInfo.name) {  // Only set if name is not already set
      console.log('Setting business info with:', {
        name: businessEntity.name,
        industry: businessEntity.industry,
        description: businessEntity.description,
        type: businessEntity.type,
        offerings: businessEntity.offerings,
      });
      setBusinessInfo(prev => ({
        ...prev,
        name: businessEntity.company_name || prev.name,
        industry: businessEntity.industry || prev.industry,
        description: businessEntity.description || prev.description,
        type: businessEntity.type || prev.type,
        offerings: businessEntity.offerings || prev.offerings,
      }));
    }
  }, [businessEntity]);

  // Add this effect to update galleryImages when pageSpecificInfo changes
  useEffect(() => {
    const gallery = pageSpecificInfo['gallery']?.gallery as GalleryImage[];
    if (gallery) {
      setGalleryImages(gallery);
    }
  }, [pageSpecificInfo]);

  const generateKeywords = useCallback(async () => {
    console.log("🚀 generateKeywords called, current loadingKeywords:", loadingKeywords);
    setLoadingKeywords("true");
    setKeywordError(false); // Reset error state
    
    try {
      const info = {
        industry: businessInfo.industry,
        offerings: businessInfo.offerings?.map(o => o.name) || [],
        locations: businessInfo.locations,
        description: businessEntity?.company_description || businessInfo.description,
        company_name: businessEntity?.company_name || businessInfo.name,
        marketing_channels: businessEntity?.marketing_channels || [],
        company_website: businessEntity?.company_website,
      };

      console.log("Sending location data to keyword generation:", info.locations);
      const keywordData = await getKeywordIdeas({ info });
      console.log("Got keyword data:", keywordData);

      const formattedKeywords = keywordData.map(([keyword, score, reasoning]: [string, string | number, Record<string, string>]) => ({
        keyword: keyword,
        score: typeof score === 'string' ? parseInt(score) : (score as number),
        reasoning: reasoning || {
          "Monthly Searches": "",
          "Competition Level": "",
          "Customer Intent": "",
          "Relevance to Business": "",
          "Search Trends": ""
        }
      }));

      const keywordsObject = formattedKeywords.reduce((acc: { [x: string]: any; }, curr: { keyword: string | number; }) => {
        acc[curr.keyword] = curr;
        return acc;
      }, {} as {[key: string]: Keyword});

      setKeywords(keywordsObject);
    } catch (error) {
      console.error('Error generating keywords:', error);
      message.error('Failed to generate keywords');
      setKeywordError(true); // Set error state
    } finally {
      setLoadingKeywords("false");
    }
  }, [businessInfo, businessEntity]);

  useEffect(() => {
    const searchTermsStep = steps.findIndex(step => step?.title === 'Search Terms');
    
    if (currentStep === searchTermsStep) {
      console.log("On Search Terms step, current state:", {
        locations: businessInfo.locations,
        loadingKeywords,
        keywordsCount: Object.keys(keywords).length,
        currentStep,
        keywordError
      });
      
      // Only generate keywords if there are no keywords, not loading, and no error
      if (!Object.keys(keywords).length && loadingKeywords === "false" && !keywordError) {
        console.log("Generating keywords");
        generateKeywords();
      }
    }
  }, [currentStep, loadingKeywords, keywords, generateKeywords, keywordError]);

  // Keep the debug useEffect for loadingKeywords
  useEffect(() => {
    console.log("loadingKeywords changed to:", loadingKeywords);
  }, [loadingKeywords]);

  // Update the Search Terms step
  const searchTermsStep = useMemo(() => ({
    title: 'Search Terms',
    content: (
      <div key={`search-terms-${loadingKeywords}`} className="space-y-6">
        <h3 className="text-xl font-medium">Let's help customers find your business online</h3>
        {/* <p className="text-gray-600">
          We'll analyze your business to identify the exact phrases potential customers use when searching for services like yours
        </p> */}
                
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {loadingKeywords === "true" ? (
            <div className="w-full h-[500px] flex flex-col items-center justify-center bg-gradient-to-b from-white to-blue-50/30">
              <div className="relative w-24 h-24 mb-8">
                <motion.div 
                  className="absolute inset-0 border-4 border-t-blue-500 border-r-blue-300 
                            border-b-blue-200 border-l-blue-100 rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
                />
                <motion.div 
                  className="absolute inset-2 border-4 border-t-blue-400 border-r-blue-200 
                            border-b-blue-100 border-l-transparent rounded-full"
                  animate={{ rotate: -360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
              </div>

              <motion.div 
                className="text-center"
                animate={{ opacity: [0.5, 1, 0.5] }}
                transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
              >
                <h3 className="text-2xl font-medium text-blue-600">
                  Finding what your customers are searching for...
                </h3>
                <p className="text-gray-600 mt-2">
                  We're analyzing search patterns to help you attract more customers
                </p>
              </motion.div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="max-h-[400px] overflow-y-auto pr-4 space-y-4">
                {Object.values(keywords).map(({ keyword, score, reasoning }, index) => (
                  <motion.div
                    key={keyword}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                    className="p-4 border-2 rounded-xl bg-white hover:border-blue-500 transition-all"
                  >
                    <div className="flex items-center justify-between">
                      <span className="text-lg">{keyword}</span>
                      <div className="flex items-center gap-2">
                        <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                          <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: `${score}%` }}
                            className={`h-full ${
                              score >= 80 ? 'bg-green-500' : 
                              score >= 60 ? 'bg-yellow-500' : 
                              'bg-red-500'
                            }`}
                          />
                        </div>
                        <span className="text-sm text-gray-600">{score}</span>
                        <Tooltip 
                          title={
                            <div className="space-y-3 p-1 text-sm">
                              {Object.entries(reasoning).map(([key, value]) => (
                                <div key={key}>
                                  <span className="font-medium">{key}:</span>
                                  <br />
                                  {value}
                                </div>
                              ))}
                            </div>
                          }
                          placement="bottom"
                          overlayClassName="z-[9999]"
                        >
                          <InfoCircleOutlined 
                            className="text-gray-400 hover:text-blue-500 cursor-help transition-colors" 
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
              <Button
                onClick={generateKeywords}
                className="w-full py-4 h-auto text-lg"
              >
                Find More Customer Search Terms
              </Button>
            </div>
          )}
        </motion.div>
      </div>
    ),
    hideNavigation: loadingKeywords === "true"
  }), [loadingKeywords, keywords, generateKeywords]);

  // Then define your steps array with the memoized searchTermsStep
  const steps = useMemo(() => {
    const baseSteps = [
      {
        title: 'Business Name',
        content: (
          <Question
            question="What's your business name?"
            subtext="This will be the primary way customers identify your brand"
          >
            <Input
              value={businessInfo.name || ''}
              onChange={(e) => setBusinessInfo({ ...businessInfo, name: e.target.value })}
              placeholder="e.g., Acme Corporation"
              className="text-2xl p-6 rounded-xl border-2 border-gray-200 hover:border-blue-500 
                       focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
              autoFocus
            />
          </Question>
        )
      },
      {
        title: 'Industry',
        content: (
          <Question
            question="What industry are you in?"
            subtext="Select your business industry to help us create the most relevant website"
          >
            <Select
              showSearch
              placeholder="Select your industry"
              value={businessInfo.industry}
              onChange={(value) => setBusinessInfo({ ...businessInfo, industry: value })}
              options={INDUSTRIES}
              className="w-full text-lg"
              size="large"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Question>
        )
      },
      {
        title: 'Location',
        content: (
          <Question
            question="Where is your business located?"
            subtext="Help customers find you by setting your location and service area"
          >
            <LocationStep
              onChange={(locations) => {
                setBusinessInfo(prev => ({
                  ...prev,
                  locations: locations
                }));
              }}
            />
          </Question>
        )
      },
      {
        title: 'Business Type',
        content: (
          <Question
            question="What do you offer to your customers?"
            subtext="Select the option that best describes your business model"
          >
            <div className="grid grid-cols-1 gap-6">
              {[
                {
                  type: 'products',
                  title: 'Products',
                  description: 'Physical or digital items that customers can purchase',
                  icon: '🛍️'
                },
                {
                  type: 'services',
                  title: 'Services',
                  description: 'Professional services or expertise you provide',
                  icon: '💼'
                },
                {
                  type: 'both',
                  title: 'Both Products & Services',
                  description: 'A combination of products and services',
                  icon: '🎯'
                }
              ].map(({ type, title, description, icon }) => (
                <motion.div
                  key={type}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`
                    p-8 border-2 rounded-2xl cursor-pointer transition-all
                    ${businessInfo.type === type 
                      ? 'border-blue-500 bg-gradient-to-r from-blue-50 to-white shadow-lg' 
                      : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'}
                  `}
                  onClick={() => setBusinessInfo(prev => ({ ...prev, type: type as WebsiteGeneratorBusinessInfo['type'] }))}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-6">
                      <div className="text-4xl">{icon}</div>
                      <div>
                        <h4 className="text-xl font-medium mb-2">{title}</h4>
                        <p className="text-gray-600">{description}</p>
                      </div>
                    </div>
                    <div className={`w-8 h-8 rounded-full border-2 flex items-center justify-center
                      ${businessInfo.type === type 
                        ? 'border-blue-500 bg-blue-500' 
                        : 'border-gray-300'}`
                    }>
                      {businessInfo.type === type && (
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          className="w-3 h-3 bg-white rounded-full"
                        />
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </Question>
        )
      },
      {
        title: 'Offerings',
        content: (
          <Question
            question={`Tell us about your ${businessInfo.type === 'both' ? 'products and services' : businessInfo.type}`}
            subtext="Add detailed information about what you offer to your customers"
          >
            <OfferingInput
              type={businessInfo.type === 'products' ? 'products' : 'services'}
              offerings={businessInfo.offerings || []}
              onChange={(offerings) => setBusinessInfo({ ...businessInfo, offerings })}
            />
          </Question>
        )
      },
      searchTermsStep,
      
    ];
    // Get relevant pages and their specific questions
    const relevantPages = getRelevantPages(businessInfo.type || 'services', businessInfo.industry || '', businessInfo.offerings);
    const pageSteps = relevantPages
      .filter(page => page.id !== 'home') // Remove home page
      .map(page => {
        const questions = getPageSpecificQuestions(page, businessInfo);
        if (!questions) return null;

        return {
          title: page.name,
          content: (
            <Question
              question={questions.question}
              subtext={questions.subtext}
            >
              <div className="space-y-8">
                {questions.fields.map((field) => {
                  switch (field.type) {
                    case 'gallery':
                      return (
                        <div key={field.key} className="space-y-4">
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && '*'}
                          </label>
                          <div className="max-h-[60vh] overflow-y-auto pr-4">
                            <GalleryUpload
                              images={pageSpecificInfo[page.id]?.gallery as GalleryImage[] || []}
                              onChange={(images) => {
                                setPageSpecificInfo(prev => ({
                                  ...prev,
                                  [page.id]: {
                                    ...prev[page.id],
                                    gallery: images
                                  }
                                }));
                              }}
                            />
                          </div>
                        </div>
                      );
                    case 'textarea':
                      return (
                        <div key={field.key} className="space-y-4">
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && <span className="text-red-500">*</span>}
                          </label>
                          <Input.TextArea
                            placeholder={field.placeholder}
                            value={pageSpecificInfo[page.id]?.[field.key] || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              setPageSpecificInfo(prev => ({
                                ...prev,
                                [page.id]: {
                                  ...prev[page.id],
                                  [field.key]: value
                                }
                              }));
                            }}
                            className="text-lg p-4 rounded-xl border-2"
                            rows={4}
                          />
                        </div>
                      );
                    case 'email':
                    case 'tel':
                    case 'text':
                      return (
                        <div key={field.key} className="space-y-4">
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && <span className="text-red-500">*</span>}
                          </label>
                          <Input
                            type={field.type}
                            placeholder={field.placeholder}
                            value={pageSpecificInfo[page.id]?.[field.key] || ''}
                            onChange={(e) => {
                              setPageSpecificInfo(prev => ({
                                ...prev,
                                [page.id]: {
                                  ...prev[page.id],
                                  [field.key]: e.target.value
                                }
                              }));
                            }}
                            className="text-lg p-4 rounded-xl border-2"
                          />
                        </div>
                      );
                    case 'blog':
                      return (
                        <BlogIdeaGenerator
                          key={field.key}
                          businessInfo={businessInfo}
                          keywords={Object.keys(keywords).filter(k => keywords[k])}
                          loading={loading}
                          onChange={(ideas) => {
                            setPageSpecificInfo({
                              ...pageSpecificInfo,
                              [page.id]: { 
                                ...pageSpecificInfo[page.id], 
                                blogIdeas: ideas 
                              }
                            });
                          }}
                        />
                      );
                    case 'radio':
                      return (
                        <div key={field.key} className="space-y-4">
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && <span className="text-red-500">*</span>}
                          </label>
                          <div className="space-y-2">
                            {field.options?.map((option: { label: string; value: string }) => (
                              <div
                                key={option.value}
                                onClick={() => {
                                  setPageSpecificInfo(prev => ({
                                    ...prev,
                                    [page.id]: {
                                      ...prev[page.id],
                                      [field.key]: option.value
                                    }
                                  }));
                                }}
                                className={`p-4 border-2 rounded-xl cursor-pointer transition-all ${
                                  pageSpecificInfo[page.id]?.[field.key] === option.value 
                                    ? 'border-blue-500 bg-blue-50' 
                                    : 'border-gray-200 hover:border-blue-200'
                                }`}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                          {field.required && !pageSpecificInfo[page.id]?.[field.key] && (
                            <p className="text-red-500 text-sm mt-1">Please select an option</p>
                          )}
                        </div>
                      );
                    case 'conditional':
                      const shouldShow = field.dependsOn && 
                                        pageSpecificInfo[page.id]?.[field.dependsOn] === field.showWhen;
                      
                      if (!shouldShow) return null;

                      return (
                        <motion.div
                          key={field.key}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="space-y-4"
                        >
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && <span className="text-red-500">*</span>}
                          </label>
                          <Input
                            type="email"
                            placeholder={field.placeholder}
                            value={value || ''}
                            onChange={(e) => onChange(e.target.value)}
                            className="text-lg p-4 rounded-xl border-2"
                          />
                          {field.required && !value && (
                            <p className="text-red-500 text-sm mt-1">This field is required</p>
                          )}
                        </motion.div>
                      );
                    case 'booking':
                      return (
                        <div key={`booking-setup-${JSON.stringify(pageSpecificInfo[page.id])}`}>
                          <BookingSetup
                            key={field.key}
                            pageId={page.id}
                            pageSpecificInfo={pageSpecificInfo}
                            onChange={(pageId, key, value) => {
                              console.log('Before update:', pageSpecificInfo);
                              setPageSpecificInfo((prev) => {
                                const updated = {
                                  ...prev,
                                  [pageId]: {
                                    ...prev[pageId],
                                    [key]: value
                                  }
                                };
                                console.log('After update:', updated);
                                return updated;
                              });
                            }}
                          />
                        </div>
                      );
                    case 'about-section':
                      return (
                        <AboutSection
                          key="about-section"
                          story={pageSpecificInfo[page.id]?.story || ''}
                          teamMembers={pageSpecificInfo[page.id]?.team || []}
                          onChange={(field, value) => {
                            console.log('About section change:', { field, value, pageId: page.id });
                            setPageSpecificInfo(prev => {
                              const updated = {
                                ...prev,
                                [page.id]: {
                                  ...prev[page.id],
                                  [field]: value
                                }
                              };
                              console.log('Updated state:', updated);
                              return updated;
                            });
                          }}
                        />
                      );
                    case 'contact-section':
                      return (
                        <ContactSection
                          key="contact-section"
                          contactInfo={pageSpecificInfo[page.id]?.contactInfo || {
                            email: '',
                            phone: '',
                            address: ''
                          }}
                          onChange={(field, value) => {
                            setPageSpecificInfo(prev => ({
                              ...prev,
                              [page.id]: {
                                ...prev[page.id],
                                contactInfo: {
                                  ...(prev[page.id]?.contactInfo || {}),
                                  [field]: value
                                }
                              }
                            }));
                          }}
                        />
                      );
                    case 'branding-section':
                      return (
                        <BrandingSection
                          key="branding-section"
                          brandingInfo={pageSpecificInfo[page.id]?.branding || {
                            type: 'custom',
                            colors: []
                          }}
                          onChange={(field, value) => {
                            setPageSpecificInfo(prev => ({
                              ...prev,
                              [page.id]: {
                                ...prev[page.id],
                                branding: {
                                  ...(prev[page.id]?.branding || {}),
                                  [field]: value
                                }
                              }
                            }));
                          }}
                        />
                      );
                    default:``
                      return (
                        <div key={field.key} className="space-y-4">
                          <label className="block text-sm font-medium text-gray-700">
                            {field.label} {field.required && '*'}
                          </label>
                          <Input
                            type={field.type}
                            placeholder={field.placeholder}
                            value={pageSpecificInfo[page.id]?.[field.key] || ''}
                            onChange={(e) => {
                              setPageSpecificInfo({
                                ...pageSpecificInfo,
                                [page.id]: { 
                                  ...pageSpecificInfo[page.id], 
                                  [field.key]: e.target.value 
                                }
                              });
                            }}
                            className="text-lg p-4 rounded-xl border-2"
                          />
                        </div>
                      );
                  }
                })}
              </div>
            </Question>
          )
        };
      })
      .filter(Boolean); // Remove null entries

    // Add Review and Generate steps
    return [...baseSteps, ...pageSteps, 
      
      {
        title: 'Review',
        content: (
          <Question
            question="Review your website details and choose branding"
            subtext="Let's finalize your website's look and feel"
          >
            <motion.div 
              className="space-y-8 bg-gray-50 p-8 rounded-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              {/* Business Info Section */}
              <div className="space-y-2">
                <h4 className="text-lg font-medium">Business Information</h4>
                <p className="text-gray-600">{businessInfo.name}</p>
                <p className="text-gray-600">{businessInfo.industry}</p>
                <p className="text-gray-600">{businessInfo.description}</p>
              </div>

              {/* Branding Options Section */}
              <div className="space-y-4">
                <h4 className="text-lg font-medium">Choose Your Branding Approach</h4>
                <div className="grid grid-cols-2 gap-4">
                  {/* Reference Website Option */}
                  <div
                    className={`p-6 border-2 rounded-xl cursor-pointer transition-all ${
                      brandingType === 'reference' 
                        ? 'border-blue-500 bg-blue-50' 
                        : 'border-gray-200 hover:border-blue-200'
                    }`}
                    onClick={() => setBrandingType('reference')}
                  >
                    <h5 className="font-medium mb-3">Use Reference Website</h5>
                    <p className="text-sm text-gray-600 mb-4">
                      Provide a website you like and we'll match its style
                    </p>
                    {brandingType === 'reference' && (
                      <Input
                        placeholder="Enter website URL (e.g., https://example.com)"
                        value={referenceUrl}
                        onChange={(e) => setReferenceUrl(e.target.value)}
                        className="mt-2"
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                  </div>

                  {/* Custom Branding Option */}
                  <div
                    className={`p-6 border-2 rounded-xl cursor-pointer transition-all ${
                      brandingType === 'custom' 
                        ? 'border-blue-500 bg-blue-50' 
                        : 'border-gray-200 hover:border-blue-200'
                    }`}
                    onClick={() => setBrandingType('custom')}
                  >
                    <h5 className="font-medium mb-3">Custom Branding</h5>
                    <p className="text-sm text-gray-600 mb-4">
                      Upload your logo and choose your brand colors
                    </p>
                    {brandingType === 'custom' && (
                      <div 
                        className="space-y-4"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* Logo Upload */}
                        <div>
                          <p className="text-sm text-gray-600 mb-2">Logo</p>
                          <Upload
                            accept="image/*"
                            showUploadList={false}
                            beforeUpload={(file) => {
                              setLogo(file);
                              setLogoPreviewUrl(URL.createObjectURL(file));
                              return false;
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              {logo ? 'Change Logo' : 'Upload Logo'}
                            </Button>
                          </Upload>
                          {logoPreviewUrl && (
                            <div className="mt-2">
                              <img
                                src={logoPreviewUrl}
                                alt="Logo preview"
                                className="h-12 object-contain"
                              />
                            </div>
                          )}
                        </div>

                        {/* Color Pickers */}
                        <div>
                          <p className="text-sm text-gray-600 mb-2">Primary Color</p>
                          <ColorPicker
                            value={primaryColor}
                            onChange={(color) => setPrimaryColor(color.toHexString())}
                          />
                        </div>
                        <div>
                          <p className="text-sm text-gray-600 mb-2">Secondary Colors</p>
                          <div className="flex flex-wrap gap-2">
                            {secondaryColors.map((color, index) => (
                              <div key={index} className="relative">
                                <ColorPicker
                                  value={color}
                                  onChange={(newColor) => {
                                    const newColors = [...secondaryColors];
                                    newColors[index] = newColor.toHexString();
                                    setSecondaryColors(newColors);
                                  }}
                                />
                                {index > 0 && (
                                  <Button
                                    type="text"
                                    size="small"
                                    icon={<CloseOutlined />}
                                    className="absolute -top-2 -right-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSecondaryColors(secondaryColors.filter((_, i) => i !== index));
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                            {secondaryColors.length < 3 && (
                              <Button
                                type="dashed"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSecondaryColors([...secondaryColors, '#000000']);
                                }}
                              >
                                Add Color
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          </Question>
        )
      },
      {
        title: 'Generate',
        content: (
          <Question
            question="Ready to create your website?"
            subtext="Click generate to start building your custom website"
          >
            <motion.div 
              className="text-center space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <p className="text-gray-600">
                We'll use all the information you've provided to create a website 
                that perfectly represents your business.
              </p>
              {loading && (
                <div className="flex items-center justify-center p-8">
                  <Spin size="large" />
                </div>
              )}
            </motion.div>
          </Question>
        )
      }
    ];
  }, [
    searchTermsStep,
    businessInfo,
    pageSpecificInfo,
    brandingType,
    referenceUrl,
    logo,
    logoPreviewUrl,
    primaryColor,
    secondaryColors,
    // ... other existing dependencies ...
  ]);

  // Add a separate effect to track step changes if needed
  useEffect(() => {
    console.log("Current step updated:", {
      currentStep,
      loadingKeywords,
      keywordsCount: Object.keys(keywords).length
    });
  }, [currentStep, loadingKeywords, keywords]);

  // Handle form submission
  const handleSubmit = async () => {
    try {
      setIsGenerating(true);

      // Upload logo first if it exists
      let logoUrl = null;
      if (logo) {
        const logoUploadResult = await uploadMediaLibraryItem(logo);
        if (logoUploadResult.success) {
          logoUrl = logoUploadResult.data.url;
        }
      }

      // Upload gallery images if they exist
      const galleryUploadPromises = (pageSpecificInfo['gallery']?.gallery || [])
        .filter(image => image.file)
        .map(async (image) => {
          const uploadResult = await uploadMediaLibraryItem(image.file);
          return {
            ...image,
            url: uploadResult.success ? uploadResult.data.url : null,
          };
        });

      const uploadedGalleryImages = await Promise.all(galleryUploadPromises);

      // Create the initial data structure with explicit page info access
      const initialData = {
        businessInfo: {
          name: businessInfo.name,
          industry: businessInfo.industry,
          description: businessInfo.description,
          type: businessInfo.type,
          competitors: businessInfo.competitors,
          locations: businessInfo.locations || []
        },
        pages: {
          home: {
            title: businessInfo.name,
            description: businessInfo.description,
            offerings: businessInfo.offerings
          },
          about: {
            story: pageSpecificInfo['about']?.story || '',
            team: pageSpecificInfo['about']?.team || []
          },
          contact: pageSpecificInfo['contact']?.contactInfo ? {
            contactInfo: pageSpecificInfo['contact'].contactInfo
          } : null,
          services: businessInfo.type !== 'products' ? {
            offerings: businessInfo.offerings?.filter(o => 
              o.offeringType === 'service' || !o.offeringType
            )
          } : null,
          products: businessInfo.type !== 'services' ? {
            offerings: businessInfo.offerings?.filter(o => 
              o.offeringType === 'product'
            )
          } : null,
          gallery: {
            images: uploadedGalleryImages
          },
          blog: pageSpecificInfo['blog']?.blogIdeas ? {
            blogIdeas: pageSpecificInfo['blog'].blogIdeas
          } : null
        },
        seo: {
          keywords: Object.entries(keywords).map(([keyword, data]) => ({
            keyword,
            score: data.score,
            reasoning: data.reasoning
          }))
        },
        branding: {
          type: brandingType,
          ...(brandingType === 'reference' 
            ? { referenceURL: referenceUrl }
            : {
                logo: logoUrl,
                colors: [primaryColor, ...secondaryColors].filter(Boolean)
              }
          )
        }
      };
      

      console.log('Branding data being sent:', {
        type: brandingType,
        logo: logoUrl,
        colors: [primaryColor, ...secondaryColors],
        referenceUrl
      });

      // Convert any remaining files to base64 (though we shouldn't have any at this point)
      const websiteData = await convertFilesToBase64(initialData);

      // Generate website blueprint with the complete data
      console.log("Generating website blueprint with data:", websiteData);
      await generateWebsiteBlueprint(websiteData);
      console.log("Website blueprint generation completed");

    } catch (error) {
      console.error('Error processing files:', error);
      setIsGenerating(false);
    }
  };

  // Add this effect to cycle through messages
  useEffect(() => {
    if (loadingKeywords === "true") {
      const timer = setInterval(() => {
        setMsgIndex((prev) => (prev >= 4 ? 0 : prev + 1));
      }, 2000);

      return () => clearInterval(timer);
    }
  }, [loadingKeywords]);

  // Add effect to load existing services into page views
  useEffect(() => {
    if (businessInfo.offerings?.length) {
      const newPageSpecificInfo = { ...pageSpecificInfo };
      selectedPages.forEach(page => {
        if (page.id === 'services' || page.id === 'products') {
          newPageSpecificInfo[page.id] = {
            ...newPageSpecificInfo[page.id],
            offerings: businessInfo.offerings.filter(o => 
              o.type === (page.id === 'services' ? 'service' : 'product')
            )
          };
        }
      });
      setPageSpecificInfo(newPageSpecificInfo);
    }
  }, [businessInfo.offerings]);

  // Update the blog generation function
  const generateBlogIdeas = useCallback(async () => {
    console.log("🚀 generateBlogIdeas called, current loadingBlogIdeas:", loadingBlogIdeas);
    setLoadingBlogIdeas("true");
    
    try {
      const ideas = await getBlogIdeas(businessInfo, Object.keys(keywords));
      console.log("Got blog ideas:", ideas);
      setBlogIdeas(ideas);
    } catch (error) {
      console.error('Error generating blog ideas:', error);
      message.error('Failed to generate blog ideas');
    } finally {
      setLoadingBlogIdeas("false");
    }
  }, [businessInfo, keywords]);

  // Update the blog step in your steps array
  const blogStep = useMemo(() => ({
    title: 'Blog',
    content: (
      <div key={`blog-${loadingBlogIdeas}`} className="space-y-6">
        <h3 className="text-xl font-medium">Blog Content</h3>
        <p className="text-gray-600">
          We'll help you create relevant content for your industry
        </p>
        
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {loadingBlogIdeas === "true" ? (
            <div className="w-full h-[500px] flex flex-col items-center justify-center bg-gradient-to-b from-white to-blue-50/30">
              <div className="relative w-24 h-24 mb-8">
                <motion.div 
                  className="absolute inset-0 border-4 border-t-blue-500 border-r-blue-300 
                            border-b-blue-200 border-l-blue-100 rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
                />
                <motion.div 
                  className="absolute inset-2 border-4 border-t-blue-400 border-r-blue-200 
                            border-b-blue-100 border-l-transparent rounded-full"
                  animate={{ rotate: -360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                />
              </div>

              <motion.div 
                className="text-center"
                animate={{ opacity: [0.5, 1, 0.5] }}
                transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
              >
                <h3 className="text-2xl font-medium text-blue-600">
                  Generating blog ideas for your business...
                </h3>
                <p className="text-gray-600 mt-2">
                  We're creating relevant content ideas for your industry
                </p>
              </motion.div>
            </div>
          ) : (
            <div className="space-y-6">
              {blogIdeas.map((idea, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="p-4 border-2 rounded-xl bg-white hover:border-blue-500 transition-all"
                >
                  <h4 className="font-medium">{idea.title}</h4>
                  <p className="text-gray-600 text-sm mt-2">{idea.summary}</p>
                </motion.div>
              ))}
              <Button
                onClick={generateBlogIdeas}
                className="w-full py-4 h-auto text-lg"
              >
                Generate More Blog Ideas
              </Button>
            </div>
          )}
        </motion.div>
      </div>
    ),
    hideNavigation: loadingBlogIdeas === "true"
  }), [loadingBlogIdeas, blogIdeas, generateBlogIdeas]);

  // Add this near the top of the component with other useEffects
  useEffect(() => {
    console.log('pageSpecificInfo updated:', pageSpecificInfo);
  }, [pageSpecificInfo]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
      className="website-generator-modal"
      centered
      destroyOnClose={true}
      maskTransitionName=""
      transitionName=""
      style={{ 
        width: 1000,
        maxHeight: '90vh'
      }}
      bodyStyle={{ 
        padding: 0,
        maxHeight: '90vh',
        overflowY: 'auto'
      }}
    >
      <AnimatePresence mode="wait">
        {visible && (
          <motion.div
            key="modal-content"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="p-16"
          >
            {(isGenerating || hasPendingWebsite) ? (
              <GeneratingView />
            ) : (
              <>
                <div className="absolute top-0 left-0 w-full h-2">
                  <motion.div
                    className="h-full bg-blue-500"
                    initial={{ width: '0%' }}
                    animate={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                
                <AnimatePresence mode="wait">
                  {steps[currentStep]?.content}
                </AnimatePresence>

                {loadingKeywords === "false" && !steps[currentStep]?.hideNavigation && (
                  <div className="flex justify-between mt-8">
                    <Button
                      onClick={() => setCurrentStep(currentStep - 1)}
                      disabled={currentStep === 0}
                    >
                      Back
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        if (currentStep === steps.length - 1) {
                          handleSubmit();
                        } else {
                          setCurrentStep(currentStep + 1);
                        }
                      }}
                      loading={loading}
                    >
                      {currentStep === steps.length - 1 ? 'Generate Website' : 'Continue'}
                    </Button>
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Modal>
  );
};


export default WebsiteGeneratorWalkthrough; 