import { Modal, Steps, TimePicker, Switch, Select, Spin, Checkbox, Card, Form, Button, message, Input, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useState, useEffect } from 'react';
import OAuthCard from "../../integrations/components/oauth-card";
import { handleGoogleOAuth, handleMetaOAuth, submitOnboardingData, listAvailablePhoneNumbers, registerEmailDomain, checkWebsiteBlueprintStatus } from "../../functions";
import { CloseOutlined, GlobalOutlined, GoogleCircleFilled, SettingOutlined, FacebookFilled, CopyOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { getIntegrationsStatus, retrieveSiteInformationForLeadGenerationPreferences } from '../../functions';
import styled from 'styled-components';
import type { Dayjs } from 'dayjs';
import { Table } from 'antd';
import WebsiteGeneratorWalkthrough from '../../website-builder/components/WebsiteGeneratorWalkthrough';
const { Item } = Form;
const { Step } = Steps;
const { Option } = Select;

import dayjs from 'dayjs';

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    border-radius: 0;
    padding: 0 8px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #1890ff;
    color: white;
  }
`;

interface Competitor {
  name: string;
  url: string;
}

interface CompetitorStateParams {
  competitors: Competitor[],
  setCompetitors: any,
  savingPreferences: boolean,
  retrievingSiteInfo: boolean,
  form: any
}

interface ServicesOrProductsParams {
  servicesOrProducts: Array<string>,
  setServicesOrProducts: any,
  savingPreferences: boolean,
  retrievingSiteInfo: boolean,
  form: any
}

interface LocationsParams {
  locations: Array<string>,
  setLocations: any,
  savingPreferences: boolean,
  retrievingSiteInfo: boolean
}

interface LocationHours {
  [key: string]: { open: Dayjs | null; close: Dayjs | null; isOpen: boolean };
}

interface Location {
  location: string;
  hours: LocationHours;
}

interface PhoneNumber {
  phone_number: string;
  friendly_name: string;
}

const CompetitorsForm = ({ competitors, setCompetitors, savingPreferences, retrievingSiteInfo, form } : CompetitorStateParams) => {
  const handleInputChange = (index: number, key: keyof Competitor, value: string) => {
    const currentCompetitors = form.getFieldValue('competitors') || [{ name: '', url: '' }];
    const newCompetitors = [...currentCompetitors];
    newCompetitors[index] = {
      ...newCompetitors[index],
      [key]: value
    };
    form.setFieldsValue({ competitors: newCompetitors });
  };

  const addCompetitor = () => {
    const currentCompetitors = form.getFieldValue('competitors') || [];
    form.setFieldsValue({ 
      competitors: [...currentCompetitors, { name: '', url: '' }] 
    });
  };

  const removeCompetitor = (index: number) => {
    const currentCompetitors = form.getFieldValue('competitors') || [];
    if (currentCompetitors.length > 1) {
      const newCompetitors = [...currentCompetitors];
      newCompetitors.splice(index, 1);
      form.setFieldsValue({ competitors: newCompetitors });
    } else {
      form.setFieldsValue({ 
        competitors: [{ name: '', url: '' }] 
      });
    }
  };

  const formCompetitors = form.getFieldValue('competitors') || [{ name: '', url: '' }];

  return (
    <div className="!mb-2">
      {formCompetitors.map((competitor: any, index: any) => (
        <div key={index} className="!w-full !flex !flex-row !gap-2">
          <Input
            type="string"
            placeholder="Competitor Name"
            className="!text-md !text-black placeholder:!text-[#C0C0C0] !h-10 !rounded-md !bg-light-200 !border-light-200 !mb-2 !w-6/12"
            value={competitor.name}
            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            disabled={savingPreferences || retrievingSiteInfo}
          />
          <Input
            type="string"
            placeholder="Competitor URL"
            className="!text-md !text-black placeholder:!text-[#C0C0C0] !h-10 !rounded-md !bg-light-200 !border-light-200 !mb-2 !w-6/12"
            value={competitor.url}
            onChange={(e) => handleInputChange(index, 'url', e.target.value)}
            disabled={savingPreferences || retrievingSiteInfo}
          />
          <Button
            type="text"
            className="!h-10"
            icon={<CloseOutlined />}
            onClick={() => removeCompetitor(index)}
            disabled={savingPreferences || retrievingSiteInfo}
          />
        </div>
      ))}
      <Button type="dashed" onClick={addCompetitor} block>
        Add Competitor
      </Button>
    </div>
  );
};

const ServicesOrProductsForm = ({ servicesOrProducts, setServicesOrProducts, savingPreferences, retrievingSiteInfo, form } : ServicesOrProductsParams) => {
  const handleInputChange = (index: number, value: string) => {
    const currentServices = form.getFieldValue('services_or_products') || [''];
    const newServices = [...currentServices];
    newServices[index] = value;
    form.setFieldsValue({ services_or_products: newServices });
  };

  const addServiceOrProduct = () => {
    const currentServices = form.getFieldValue('services_or_products') || [];
    form.setFieldsValue({ 
      services_or_products: [...currentServices, ''] 
    });
  };

  const removeServiceOrProduct = (index: number) => {
    const currentServices = form.getFieldValue('services_or_products') || [];
    if (currentServices.length > 1) {
      const newServices = [...currentServices];
      newServices.splice(index, 1);
      form.setFieldsValue({ services_or_products: newServices });
    } else {
      form.setFieldsValue({ 
        services_or_products: [''] 
      });
    }
  };

  const formServices = form.getFieldValue('services_or_products') || [''];

  return (
    <div className="!mb-2">
      {formServices.map((serviceOrProduct: any, index: any) => (
        <div key={index} className="!w-full !flex !flex-row !gap-2">
          <Input
            type="string"
            placeholder="Service or Product Name"
            className="!text-md !text-black placeholder:!text-[#C0C0C0] !h-10 !rounded-md !bg-light-200 !border-light-200 !mb-2 !w-full"
            value={serviceOrProduct}
            onChange={(e) => handleInputChange(index, e.target.value)}
            disabled={savingPreferences || retrievingSiteInfo}
          />
          <Button
            type="text"
            className="!h-10"
            icon={<CloseOutlined />}
            onClick={() => removeServiceOrProduct(index)}
            disabled={savingPreferences || retrievingSiteInfo}
          />
        </div>
      ))}
      <Button type="dashed" onClick={addServiceOrProduct} block>
        Add Service or Product
      </Button>
    </div>
  );
};

interface SetupModalProps {
  visible: boolean;
  onClose: () => void;
  serviceName: string | null;
  setupData?: any;
  hasPendingWebsite?: boolean;
}

const SetupModal = ({ visible, onClose, serviceName, setupData, hasPendingWebsite }: SetupModalProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [googleLinked, setGoogleLinked] = useState(false);
  const [facebookLinked, setFacebookLinked] = useState(false);
  const [locations, setLocations] = useState<Location[]>([{
    location: '',
    hours: {
      Monday: { open: null, close: null, isOpen: false },
      Tuesday: { open: null, close: null, isOpen: false },
      Wednesday: { open: null, close: null, isOpen: false },
      Thursday: { open: null, close: null, isOpen: false },
      Friday: { open: null, close: null, isOpen: false },
      Saturday: { open: null, close: null, isOpen: false },
      Sunday: { open: null, close: null, isOpen: false },
    }
  }]);
  const [previousLocations, setPreviousLocations] = useState<any[]>([]);
  const [serviceArea, setServiceArea] = useState('specific');
  const [retrievingSiteInfo, setRetrievingSiteInfo] = useState(false);
  const [availableNumbers, setAvailableNumbers] = useState<PhoneNumber[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<string>('');
  const [searchingNumbers, setSearchingNumbers] = useState(false);
  const [registeringEmailDomain, setRegisteringEmailDomain] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [domainVerified, setDomainVerified] = useState(false);

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const addLocation = () => {
    setLocations([...locations, {
      location: '',
      hours: {
        Monday: { open: null, close: null, isOpen: false },
        Tuesday: { open: null, close: null, isOpen: false },
        Wednesday: { open: null, close: null, isOpen: false },
        Thursday: { open: null, close: null, isOpen: false },
        Friday: { open: null, close: null, isOpen: false },
        Saturday: { open: null, close: null, isOpen: false },
        Sunday: { open: null, close: null, isOpen: false },
      }
    }]);
  };

  const removeLocation = (index: number) => {
    if (locations.length > 1) {
      const newLocations = [...locations];
      newLocations.splice(index, 1);
      setLocations(newLocations);
    } else {
      setLocations([{
        location: '',
        hours: {
          Monday: { open: null, close: null, isOpen: false },
          Tuesday: { open: null, close: null, isOpen: false },
          Wednesday: { open: null, close: null, isOpen: false },
          Thursday: { open: null, close: null, isOpen: false },
          Friday: { open: null, close: null, isOpen: false },
          Saturday: { open: null, close: null, isOpen: false },
          Sunday: { open: null, close: null, isOpen: false },
        }
      }]);
    }
  };


  const handleLocationChange = (index: number, field: string, value: any) => {
    const newLocations = [...locations];
    if (field === 'location') {
      newLocations[index].location = value;
    } else if (field.endsWith('_isOpen')) {
      const day = field.split('_')[0];
      newLocations[index].hours[day].isOpen = value;
      if (!value) {
        newLocations[index].hours[day].open = null;
        newLocations[index].hours[day].close = null;
      }
    } else {
      const [day, timeType] = field.split('_');
      newLocations[index].hours[day][timeType as 'open' | 'close'] = value;
    }
    setLocations(newLocations);
  };

  const handleServiceAreaChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setServiceArea(value);
    if (value === 'wholeUS') {
      setPreviousLocations(locations);
      setLocations([{
        location: 'United States',
        hours: {
          Monday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Tuesday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Wednesday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Thursday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Friday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Saturday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
          Sunday: { open: dayjs('00:00', 'HH:mm'), close: dayjs('23:59', 'HH:mm'), isOpen: true },
        }
      }]);
    } else {
      if (previousLocations.length > 0) {
        setLocations(previousLocations);
      } else {
        setLocations([{
          location: '',
          hours: {
            Monday: { open: null, close: null, isOpen: false },
            Tuesday: { open: null, close: null, isOpen: false },
            Wednesday: { open: null, close: null, isOpen: false },
            Thursday: { open: null, close: null, isOpen: false },
            Friday: { open: null, close: null, isOpen: false },
            Saturday: { open: null, close: null, isOpen: false },
            Sunday: { open: null, close: null, isOpen: false },
          }
        }]);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      // Check integrations status when modal becomes visible
      getIntegrationsStatus().then((data) => {
        if (data?.integrations_status) {
          setGoogleLinked(data.integrations_status.google_linked || false);
          setFacebookLinked(data.integrations_status.meta_linked || false);
        }
      }).catch((error) => {
        console.error('Error checking integrations status:', error);
        setGoogleLinked(false);
        setFacebookLinked(false);
      });
    }
  }, [visible]); // Only run when visibility changes

  // Initialize form and locations with setup data when modal becomes visible
  useEffect(() => {
    if (visible && setupData) {
      form.setFieldsValue(setupData);

      // Prefill locations and hours if they exist
      if (setupData.company_locations && setupData.company_locations.length > 0) {
        const prefillLocations = setupData.company_locations.map((loc: any) => {
          if (loc.hours) {
            return {
              location: loc.location,
              hours: {
                Monday: { 
                  open: loc.hours.MONDAY ? dayjs(loc.hours.MONDAY[0], 'HH:mm') : null, 
                  close: loc.hours.MONDAY ? dayjs(loc.hours.MONDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.MONDAY 
                },
                Tuesday: { 
                  open: loc.hours.TUESDAY ? dayjs(loc.hours.TUESDAY[0], 'HH:mm') : null, 
                  close: loc.hours.TUESDAY ? dayjs(loc.hours.TUESDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.TUESDAY 
                },
                Wednesday: { 
                  open: loc.hours.WEDNESDAY ? dayjs(loc.hours.WEDNESDAY[0], 'HH:mm') : null, 
                  close: loc.hours.WEDNESDAY ? dayjs(loc.hours.WEDNESDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.WEDNESDAY 
                },
                Thursday: { 
                  open: loc.hours.THURSDAY ? dayjs(loc.hours.THURSDAY[0], 'HH:mm') : null, 
                  close: loc.hours.THURSDAY ? dayjs(loc.hours.THURSDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.THURSDAY 
                },
                Friday: { 
                  open: loc.hours.FRIDAY ? dayjs(loc.hours.FRIDAY[0], 'HH:mm') : null, 
                  close: loc.hours.FRIDAY ? dayjs(loc.hours.FRIDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.FRIDAY 
                },
                Saturday: { 
                  open: loc.hours.SATURDAY ? dayjs(loc.hours.SATURDAY[0], 'HH:mm') : null, 
                  close: loc.hours.SATURDAY ? dayjs(loc.hours.SATURDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.SATURDAY 
                },
                Sunday: { 
                  open: loc.hours.SUNDAY ? dayjs(loc.hours.SUNDAY[0], 'HH:mm') : null, 
                  close: loc.hours.SUNDAY ? dayjs(loc.hours.SUNDAY[1], 'HH:mm') : null, 
                  isOpen: !!loc.hours.SUNDAY 
                },
              }
            };
          } else {
            // If hours are not present, return location with default hours
            return {
              location: loc.location,
              hours: {
                Monday: { open: null, close: null, isOpen: false },
                Tuesday: { open: null, close: null, isOpen: false },
                Wednesday: { open: null, close: null, isOpen: false },
                Thursday: { open: null, close: null, isOpen: false },
                Friday: { open: null, close: null, isOpen: false },
                Saturday: { open: null, close: null, isOpen: false },
                Sunday: { open: null, close: null, isOpen: false },
              }
            };
          }
        });
        setLocations(prefillLocations);
        setServiceArea('specific');
      }

      // Prefill lead generation preferences if they exist
      if (setupData.lead_gen_preferences) {
        const leadGenPrefs = setupData.lead_gen_preferences;
        form.setFieldsValue({
          business_description: leadGenPrefs.business_description,
          target_customers: leadGenPrefs.target_customers,
          competitors: leadGenPrefs.competitors || [{ name: '', url: '' }],
          services_or_products: leadGenPrefs.services_or_products || [''],
          monthly_budget: leadGenPrefs.monthly_budget
        });
      }
    }
  }, [visible, setupData, form]);

  const renderStepContent = (content: React.ReactNode) => (
    <div className="bg-white p-8 rounded-lg border border-gray-100 shadow-sm">
      {content}
    </div>
  );

  const handleScanSite = async () => {
    setRetrievingSiteInfo(true);
    try {
      const website = form.getFieldValue('company_website');
      if (website) {
        const data = await retrieveSiteInformationForLeadGenerationPreferences(website);
        console.log("Site Scan Data", data);
        form.setFieldsValue({
          business_description: data.business_description,
          competitors: data.competitors,
          services_or_products: data.services_or_products,
          monthly_budget: data.monthly_budget,
        });
        message.success("Retrieved business info from site successfully!");
      } else {
        message.error("Please enter a valid site URL first");
      }
    } catch (error) {
      console.error('Error scanning site:', error);
      message.error("Something went wrong. Please try again later.");
    } finally {
      setRetrievingSiteInfo(false);
    }
  };

  const getServiceSteps = () => {
    const commonSteps = [
      {
        title: 'Website',
        description: 'Enter your website details',
        icon: <GlobalOutlined className="text-[#2563eb]" />,
        content: renderStepContent(
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Website Information</h3>
            <p className="text-gray-600 mb-6">
              Please provide your website URL so we can properly configure your channel.
            </p>
            <Form.Item 
              name="company_website" 
              label="Company Website" 
              rules={[{ required: true, type: 'url', message: 'Please enter a valid company website URL' }]}
            >
              <Input disabled={isLoading} placeholder="https://www.example.com" />
            </Form.Item>
          </div>
        )
      },
      {
        title: 'Company Details',
        content: (
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto pr-4">
            <Item name="company_name" label="Company Name" rules={[{ required: true, message: 'Please enter your company name' }]}>
              <Input disabled={isLoading} />
            </Item>
            <Item name="company_phone" label="Company Phone Number (optional)">
              <Input disabled={isLoading} />
            </Item>
            <Item name="company_email" label="Company Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid company email' }]}>
              <Input disabled={isLoading} />
            </Item>
            <Form.Item
              name="company_description"
              label="Company Description"
              rules={[{ required: true, message: 'Please provide a company description' }]}
            >
              <Input.TextArea 
                rows={4} 
                disabled={isLoading}
                placeholder="Provide a description of your company."
                className="!font-body"
              />
            </Form.Item>
            <Form.Item label="Service Area">
              <StyledRadioGroup
                value={serviceArea}
                onChange={handleServiceAreaChange}
                disabled={isLoading}
                buttonStyle="solid"
              >
                <Radio.Button value="specific">Specific Areas</Radio.Button>
                <Radio.Button value="wholeUS">Entire US</Radio.Button>
              </StyledRadioGroup>
            </Form.Item>
            {serviceArea === 'specific' && (
              <Form.Item label="Company Locations & Hours" required>
                {locations.map((loc, index) => (
                  <div key={index} className="mb-4 p-4 border rounded">
                    <Input
                      value={loc.location}
                      onChange={(e) => handleLocationChange(index, 'location', e.target.value)}
                      placeholder="Location"
                      disabled={isLoading}
                    />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                      {Object.entries(loc.hours).map(([day, times]) => (
                        <div key={day} className="flex flex-col">
                          <Checkbox
                            checked={times.isOpen}
                            onChange={(e) => handleLocationChange(index, `${day}_isOpen`, e.target.checked)}
                          >
                            {day}
                          </Checkbox>
                          {times.isOpen && (
                            <div className="flex flex-col sm:flex-row gap-2 mt-2">
                              <TimePicker
                                use12Hours
                                format="h:mm A"
                                value={times.open}
                                onChange={(time) => handleLocationChange(index, `${day}_open`, time)}
                                disabled={isLoading}
                                className="mb-2"
                              />
                              <TimePicker
                                use12Hours
                                format="h:mm A"
                                value={times.close}
                                onChange={(time) => handleLocationChange(index, `${day}_close`, time)}
                                disabled={isLoading}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => removeLocation(index)}
                      disabled={isLoading || locations.length === 1}
                      className="mt-4"
                    >
                      Remove Location
                    </Button>
                  </div>
                ))}
                <Button type="dashed" onClick={addLocation} block disabled={isLoading}>
                  Add Location
                </Button>
              </Form.Item>
            )}
          </div>
        ),
      },
    ];

    switch (serviceName) {
      case 'google_ads':
        return [
          {
            title: 'Link Google Account',
            description: 'Connect your Google Ads account',
            icon: <GoogleCircleFilled className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Connect Google Ads</h3>
                <p className="text-gray-600 mb-6">
                  Link your Google Ads account to enable campaign management and optimization.
                </p>
                <OAuthCard
                  title="Google Business"
                  description="Connect your Google Ads account to manage your advertising campaigns."
                  redirectOAuth={handleGoogleOAuth}
                  linked={googleLinked}
                />
              </div>
            )
          },
          ...commonSteps,
          {
            title: 'Ad-Related Settings',
            description: 'Configure your ad preferences',
            icon: <SettingOutlined className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Campaign Configuration</h3>
                <p className="text-gray-600 mb-6">
                  Set up your campaign preferences to reach your target audience effectively.
                </p>
                <Button
                  onClick={handleScanSite}
                  loading={retrievingSiteInfo}
                  disabled={isLoading || retrievingSiteInfo}
                  className="mb-4"
                >
                  Fill out the below form based on website info
                </Button>
                <Form.Item
                  name="business_description"
                  label="Business Description"
                  rules={[{ required: true, message: 'Please provide a business description' }]}
                >
                  <Input.TextArea 
                    rows={4} 
                    disabled={isLoading || retrievingSiteInfo}
                    placeholder="Provide a description of your business."
                    className="font-body"
                  />
                </Form.Item>
                <Form.Item
                  name="target_customers"
                  label="Target Customer(s)"
                  rules={[{ required: true, message: 'Please describe your target customers' }]}
                >
                  <Input.TextArea 
                    rows={4} 
                    disabled={isLoading || retrievingSiteInfo}
                    placeholder="Describe your target customer(s)"
                    className="font-body"
                  />
                </Form.Item>
                <Form.Item
                  name="competitors"
                  label="Competitors"
                  rules={[{ required: true, message: 'Please add at least one competitor' }]}
                >
                  <CompetitorsForm
                    competitors={form.getFieldValue('competitors') || [{ name: '', url: '' }]}
                    setCompetitors={(value: any) => {
                      form.setFieldsValue({ competitors: value });
                    }}
                    savingPreferences={isLoading}
                    retrievingSiteInfo={retrievingSiteInfo}
                    form={form}
                  />
                </Form.Item>
                <Form.Item
                  name="services_or_products"
                  label="Services or Products"
                  rules={[{ required: true, message: 'Please add at least one service or product' }]}
                >
                  <ServicesOrProductsForm
                    servicesOrProducts={form.getFieldValue('services_or_products') || ['']}
                    setServicesOrProducts={(value: any) => {
                      form.setFieldsValue({ services_or_products: value });
                    }}
                    savingPreferences={isLoading}
                    retrievingSiteInfo={retrievingSiteInfo}
                    form={form}
                  />
                </Form.Item>
                <Form.Item
                  name="monthly_budget"
                  label="Monthly Ad Spend Budget"
                  rules={[{ required: true, message: 'Please enter a monthly budget' }]}
                >
                  <Input 
                    type="number" 
                    disabled={isLoading || retrievingSiteInfo}
                    prefix="$" 
                    placeholder="1000"
                  />
                </Form.Item>
              </div>
            )
          }
        ];

      case 'meta_ads':
        return [
          {
            title: 'Link Meta Ads Account',
            description: 'Connect your Meta (Facebook & Instagram) Ads account',
            icon: <FacebookFilled className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Connect Facebook Ads</h3>
                <p className="text-gray-600 mb-6">
                  Link your Facebook Ads account to enable campaign management and optimization.
                </p>
                <OAuthCard
                  title="Meta (Facebook & Instagram) Ads"
                  description="Connect your Meta (Facebook & Instagram) ads account to manage your advertising campaigns."
                  redirectOAuth={handleMetaOAuth}
                  linked={facebookLinked}
                />
              </div>
            )
          },
          ...commonSteps,
          {
            title: 'Ad-Related Settings',
            description: 'Configure your ad preferences',
            icon: <SettingOutlined className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Campaign Configuration</h3>
                <p className="text-gray-600 mb-6">
                  Set up your campaign preferences to reach your target audience effectively.
                </p>
                <Form.Item
                  name="business_description"
                  label="Business Description"
                  rules={[{ required: true, message: 'Please provide a business description' }]}
                >
                  <Input.TextArea 
                    rows={4} 
                    disabled={isLoading} 
                    placeholder="Describe your business and what makes it unique..."
                  />
                </Form.Item>
                <Form.Item
                  name="target_customers"
                  label="Target Customer(s)"
                  rules={[{ required: true, message: 'Please describe your target customers' }]}
                >
                  <Input.TextArea 
                    rows={4} 
                    disabled={isLoading} 
                    placeholder="Describe your ideal customers and their characteristics..."
                  />
                </Form.Item>
                <Form.Item
                  name="monthly_budget"
                  label="Monthly Ad Spend Budget"
                  rules={[{ required: true, message: 'Please enter a monthly budget' }]}
                >
                  <Input 
                    type="number" 
                    disabled={isLoading} 
                    prefix="$" 
                    placeholder="1000"
                  />
                </Form.Item>
              </div>
            )
          }
        ];

      case 'social_media':
        return [
          {
            title: 'Link Facebook & Instagram Accounts',
            description: 'Connect your Facebook & Instagram accounts',
            icon: <FacebookFilled className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Connect Facebook Ads</h3>
                <p className="text-gray-600 mb-6">
                  Link your Facebook Ads account to enable campaign management and optimization.
                </p>
                <OAuthCard
                  title="Facebook & Instagram Accounts"
                  description="Connect your Facebook & Instagram social accounts to manage your social media presence."
                  redirectOAuth={handleMetaOAuth}
                  linked={facebookLinked}
                />
              </div>
            )
          },
          ...commonSteps,
        ]
      case 'business_listings':
        return [
          {
            title: 'Link Google Account',
            description: 'Connect your Google Business account',
            icon: <GoogleCircleFilled className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Connect Google Ads</h3>
                <p className="text-gray-600 mb-6">
                  Link your Google Business account to enable business profile and review management.
                </p>
                <OAuthCard
                  title="Google Business"
                  description="Connect your Google Business account."
                  redirectOAuth={handleGoogleOAuth}
                  linked={googleLinked}
                />
              </div>
            )
          },
          ...commonSteps,
        ];
      case 'text_marketing':
        return [
          ...commonSteps,
          {
            title: 'Select Phone Number',
            description: 'Choose a phone number for text marketing',
            icon: <BuildingOfficeIcon className="w-5 h-5 text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Select Phone Number</h3>
                <p className="text-gray-600 mb-6">
                  Search for available phone numbers by area code to use for your text marketing campaigns.
                </p>
                
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Area Code</label>
                  <div className="flex gap-2">
                    <Input 
                      value={areaCode}
                      placeholder="Enter area code (e.g. 415)"
                      maxLength={3}
                      disabled={searchingNumbers}
                      className="!w-32"
                      onChange={(e) => {
                        // Ensure only numbers are entered
                        const value = e.target.value.replace(/[^\d]/g, '');
                        setAreaCode(value);
                      }}
                    />
                    <Button
                      onClick={async () => {
                        try {
                          if (areaCode && areaCode.length === 3) {
                            setSearchingNumbers(true);
                            setAvailableNumbers([]);
                            setSelectedNumber('');
                            
                            const numbers = await listAvailablePhoneNumbers(areaCode);
                            if (numbers && numbers.length > 0) {
                              setAvailableNumbers(numbers);
                            } else {
                              message.error('No numbers found for this area code');
                            }
                          } else {
                            message.error('Please enter a valid 3-digit area code');
                          }
                        } catch (error) {
                          console.error('Error searching numbers:', error);
                          message.error('Failed to search for numbers');
                        } finally {
                          setSearchingNumbers(false);
                        }
                      }}
                      loading={searchingNumbers}
                      disabled={!areaCode || areaCode.length !== 3}
                    >
                      Search
                    </Button>
                  </div>
                </div>

                {availableNumbers.length > 0 && (
                  <div className="mt-4">
                    <h4 className="font-medium mb-2">Available Numbers:</h4>
                    <div className="grid gap-2">
                      {availableNumbers.map((number) => (
                        <Card
                          key={number.phone_number}
                          className={`cursor-pointer transition-all ${
                            selectedNumber === number.phone_number 
                              ? 'border-[#2563eb] shadow-sm' 
                              : 'border-gray-200'
                          }`}
                          onClick={() => {
                            setSelectedNumber(number.phone_number);
                            form.setFieldsValue({ selected_phone_number: number.phone_number });
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <span className="font-medium">{number.friendly_name}</span>
                            <Radio checked={selectedNumber === number.phone_number} />
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                )}

                <Form.Item
                  name="selected_phone_number"
                  hidden
                  rules={[{ required: true, message: 'Please select a phone number' }]}
                >
                  <Input />
                </Form.Item>
              </div>
            )
          },
        ];
      case 'email_marketing':
        return [
          ...commonSteps,
          {
            title: 'Email Domain Setup',
            description: 'Configure your email sending domain',
            icon: <SettingOutlined className="text-[#2563eb]" />,
            content: renderStepContent(
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Email Domain Configuration</h3>
                <p className="text-gray-600 mb-6">
                  Set up your domain for sending email marketing campaigns. You'll need to add DNS records to verify ownership.
                </p>
                
                <Form.Item
                  name="email_domain"
                  label="Email Sending Domain"
                  rules={[{ required: true, message: 'Please enter your domain' }]}
                >
                  <Input 
                    placeholder="yourdomain.com"
                    disabled={isLoading}
                    suffix={
                      <Button 
                        type="primary"
                        onClick={async () => {
                          try {
                            setRegisteringEmailDomain(true);
                            const domain = form.getFieldValue('email_domain');
                            const result = await registerEmailDomain(domain);
                            
                            if (!result) {
                              message.error('Email domain could not be registered');
                              return;
                            }
                            
                            // Store verification instructions and status in form data
                            form.setFieldsValue({ 
                              verification_instructions: result.verification_instructions,
                              domain_verification_status: result.status
                            });
                            
                            // Update verification status
                            setDomainVerified(result.status === 'verified');
                            if (result.status === 'verified') {
                              setNextButtonDisabled(false);
                            }
                            
                            if (result.status === 'verified') {
                              message.success('Domain is verified and ready for email sending!');
                            } else {
                              message.info('Please add the DNS records below to verify your domain.');
                            }
                          } catch (error) {
                            console.error('Error registering domain:', error);
                            message.error('Email domain could not be registered');
                          } finally {
                            setRegisteringEmailDomain(false);
                          }
                        }}
                        loading={registeringEmailDomain}
                      >
                        Verify Domain
                      </Button>
                    }
                  />
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => 
                    prevValues?.verification_instructions !== currentValues?.verification_instructions ||
                    prevValues?.domain_verification_status !== currentValues?.domain_verification_status
                  }
                >
                  {({ getFieldValue }) => {
                    const instructions = getFieldValue('verification_instructions');
                    const status = getFieldValue('domain_verification_status');
                    
                    if (!instructions) return null;

                    return (
                      <div className="space-y-4">
                        <div className="flex items-center justify-between p-4 border rounded-lg bg-gray-50">
                          <div className="flex items-center gap-3">
                            <span className="font-medium">Domain Verification Status:</span>
                            <span className={status === 'verified' ? 'text-green-600' : 'text-red-600'}>
                              {status === 'verified' ? 'Verified' : 'Not Verified'}
                            </span>
                          </div>
                          {status === 'verified' ? (
                            <CheckCircleFilled className="text-xl !text-green-600" />
                          ) : (
                            <CloseCircleFilled className="text-xl !text-red-600" />
                          )}
                        </div>

                        {status !== 'verified' && (
                          <>
                            <h4 className="font-medium mt-6 mb-2">Required DNS Records</h4>
                            <p className="text-gray-600 mb-4">
                              Add these DNS records to your domain to verify ownership. DNS changes may take up to 24-48 hours to propagate.
                            </p>
                            <div className="max-h-[300px] overflow-auto">
                              <Table 
                                columns={[
                                  {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    key: 'type',
                                    width: 80,
                                    fixed: 'left',
                                    className: 'whitespace-nowrap px-4 py-2',
                                  },
                                  {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                    width: 300,
                                    className: 'whitespace-nowrap px-4 py-2',
                                    render: (text: string) => (
                                      <div className="flex items-center gap-2">
                                        <div className="truncate max-w-[240px]">
                                          <span className="font-mono text-sm">{text}</span>
                                        </div>
                                        <Button 
                                          type="text" 
                                          icon={<CopyOutlined />}
                                          onClick={() => {
                                            navigator.clipboard.writeText(text);
                                            message.success('Copied to clipboard');
                                          }}
                                          className="flex items-center justify-center min-w-[32px]"
                                        />
                                      </div>
                                    ),
                                  },
                                  {
                                    title: 'Value',
                                    dataIndex: 'value',
                                    key: 'value',
                                    width: 300,
                                    className: 'whitespace-nowrap px-4 py-2',
                                    render: (text: string) => (
                                      <div className="flex items-center gap-2">
                                        <div className="truncate max-w-[240px]">
                                          <span className="font-mono text-sm">{text}</span>
                                        </div>
                                        <Button 
                                          type="text" 
                                          icon={<CopyOutlined />}
                                          onClick={() => {
                                            navigator.clipboard.writeText(text);
                                            message.success('Copied to clipboard');
                                          }}
                                          className="flex items-center justify-center min-w-[32px]"
                                        />
                                      </div>
                                    ),
                                  },
                                ]} 
                                dataSource={[
                                  {
                                    key: 'domain_verification',
                                    type: instructions.domain_verification.record_type,
                                    name: instructions.domain_verification.name,
                                    value: instructions.domain_verification.value,
                                  },
                                  ...instructions.dkim_records.map((record: any, index: number) => ({
                                    key: `dkim_${index}`,
                                    type: record.record_type,
                                    name: record.name,
                                    value: record.value,
                                  }))
                                ]}
                                pagination={false}
                                scroll={{ x: 680, y: 240 }}
                                className="[&_.ant-table-body]:overflow-auto [&_.ant-table-cell]:whitespace-nowrap [&_.ant-table-cell]:px-4 [&_.ant-table-cell]:py-2"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    );
                  }}
                </Form.Item>
              </div>
            )
          }
        ];
      // Add other services with their specific steps
      default:
        return commonSteps;
    }
  };

  const steps = getServiceSteps();

  const handleNext = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      
      // Format the data based on the current step and service
      let formattedData = { ...values };

      // Format locations data when completing the company details step
      if (locations.some(loc => loc.location !== '')) {
        const formattedLocations = locations.map(loc => ({
          location: loc.location,
          hours: Object.entries(loc.hours).reduce<Record<string, [string, string] | null>>((acc, [day, times]) => {
            acc[day.toUpperCase()] = times.isOpen ? [times.open?.format('HH:mm') || '', times.close?.format('HH:mm') || ''] : null;
            return acc;
          }, {})
        }));
        formattedData.company_locations = formattedLocations;
      }

      if (serviceName === 'email_marketing' && currentStep === steps.length - 2) {
        form.setFieldsValue({'email_domain': form.getFieldValue('company_website').replace('https://', '').replace('http://', '').replace('www.', '')});
        setNextButtonDisabled(true);
      }

      // Format lead generation preferences for Google Ads
      if (serviceName === 'google_ads' && currentStep === steps.length - 1) {
        const leadGenPreferences = {
          business_description: values.business_description,
          target_customers: values.target_customers,
          competitors: values.competitors,
          services_or_products: values.services_or_products,
          google_ads_preference: 'fully_automated', // Default to fully_automated
          monthly_budget: values.monthly_budget,
        };

        formattedData.lead_gen_preferences = leadGenPreferences;

        // Remove the lead gen fields from the top level
        delete formattedData.business_description;
        delete formattedData.target_customers;
        delete formattedData.competitors;
        delete formattedData.services_or_products;
        delete formattedData.monthly_budget;
      }

      // Submit the formatted data
      const result = await submitOnboardingData(formattedData);

      if (result) {
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1);
        } else {
          message.success('Setup completed successfully');
          onClose();
          window.location.reload(); // Refresh to show updated status
        }
      }
    } catch (error) {
      console.error('Setup error:', error);
      message.error('Failed to save setup information');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };


  return (
    serviceName === 'seo' ? (
      <WebsiteGeneratorWalkthrough 
        visible={visible}
        onClose={onClose}
        loading={isLoading}
        hasPendingWebsite={hasPendingWebsite}
      />
    ): 
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        width={800}
        title={
        <div className="flex items-center justify-between pt-2 pr-8">
          <span className="text-xl font-semibold">
            Setup {serviceName?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
          </span>
          <span className="text-sm text-gray-500 font-medium">
            Step {currentStep + 1} of {steps.length}
          </span>
        </div>
      }
      className="setup-modal"
      styles={{ 
        header: {
          paddingBottom: '1rem',
          marginBottom: '0',
        },
        body: {
          paddingTop: '0',
        }
      }}
    >
      <div className="py-12 px-8">
        <div className="mb-12">
          <div className="h-1 bg-gray-100 rounded-full">
            <div 
              className="h-1 bg-[#2563eb] rounded-full transition-all duration-300"
              style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
            />
          </div>
        </div>

        <Form
          form={form}
          layout="vertical"
        >
          <div className="mb-8 text-center">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              {steps[currentStep].title}
            </h3>
            <p className="text-gray-500">
              {steps[currentStep].description}
            </p>
          </div>

          <div className="mb-8">
            {steps[currentStep].content}
          </div>

          <div className="flex justify-center gap-4">
            {currentStep > 0 && (
              <Button 
                onClick={handlePrevious} 
                disabled={isLoading}
                size="large"
              >
                Previous
              </Button>
            )}
            <Button
              type="primary"
              onClick={handleNext}
              loading={isLoading}
              disabled={nextButtonDisabled}
              size="large"
              className="bg-[#2563eb] hover:bg-[#1d4ed8]"
            >
              {currentStep === steps.length - 1 ? 'Complete Setup' : 'Next'}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default SetupModal;