import { PageTemplate, WebsiteGeneratorBusinessInfo } from '../types/websiteGenerator';

export const getRelevantPages = (
  businessType: string, 
  industry: string, 
  offerings: any[] = []
): PageTemplate[] => {
  const basePages: PageTemplate[] = [
    { id: 'home', name: 'Home', description: 'Main landing page', selected: true },
    { id: 'about', name: 'About', description: 'Company information', selected: true },
    { id: 'contact', name: 'Contact', description: 'Contact information', selected: true },
    { id: 'gallery', name: 'Gallery', description: 'Image gallery', selected: true },
    { id: 'blog', name: 'Blog', description: 'Blog content', selected: true },
  ];

  // Add services/products pages based on business type
  if (businessType === 'services' || businessType === 'both') {
    basePages.push({ id: 'services', name: 'Services', description: 'List of services offered', selected: true });
  }
  if (businessType === 'products' || businessType === 'both') {
    basePages.push({ id: 'products', name: 'Products', description: 'Product catalog', selected: true });
  }

  // Add booking page option for salons
  if (industry?.toLowerCase() === 'salons') {
    basePages.push({ 
      id: 'booking', 
      name: 'Booking', 
      description: 'Online appointment scheduling', 
      selected: false 
    });
  }

  return basePages;
}; 