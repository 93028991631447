import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { MdClose } from 'react-icons/md';
import { motion } from 'framer-motion';

interface BrandColor {
  id: string;
  color: string;
}

interface BrandingInfo {
  type: 'custom' | 'reference';
  referenceURL?: string;
  logo?: File;
  colors: BrandColor[];
}

interface BrandingSectionProps {
  brandingInfo: BrandingInfo;
  onChange: (field: keyof BrandingInfo, value: any) => void;
}

const BrandingSection: React.FC<BrandingSectionProps> = ({ brandingInfo, onChange }) => {
  const [brandingType, setBrandingType] = useState<'custom' | 'reference'>(brandingInfo?.type || 'custom');
  const [referenceURL, setReferenceURL] = useState(brandingInfo?.referenceURL || '');
  const [logo, setLogo] = useState<File | undefined>(brandingInfo?.logo);
  const [brandColors, setBrandColors] = useState<BrandColor[]>(brandingInfo?.colors || []);

  useEffect(() => {
    onChange('type', brandingType);
    if (brandingType === 'reference') {
      onChange('referenceURL', referenceURL);
    } else {
      onChange('logo', logo);
      onChange('colors', brandColors);
    }
  }, [brandingType, referenceURL, logo, brandColors, onChange]);

  const handleChange = (field: string, value: any) => {
    console.log('BrandingSection change:', { field, value });
    onChange(field as keyof BrandingInfo, value);
  };

  const handleBrandingTypeChange = (type: 'custom' | 'reference') => {
    setBrandingType(type);
    handleChange('type', type);
  };

  const handleReferenceURLChange = (url: string) => {
    setReferenceURL(url);
    handleChange('referenceURL', url);
  };

  const handleLogoChange = (file: File | undefined) => {
    setLogo(file);
    handleChange('logo', file);
  };

  const handleColorChange = (colors: BrandColor[]) => {
    setBrandColors(colors);
    handleChange('colors', colors);
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-6">
        {[
          {
            type: 'reference',
            title: 'Use Reference Website',
            description: 'We\'ll match the style of another website you like',
            icon: '🔗'
          },
          {
            type: 'custom',
            title: 'Custom Branding',
            description: 'Upload your logo and choose your brand colors',
            icon: '🎨'
          }
        ].map(({ type, title, description, icon }) => (
          <motion.div
            key={type}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`
              p-8 border-2 rounded-2xl cursor-pointer transition-all
              ${brandingType === type 
                ? 'border-blue-500 bg-gradient-to-r from-blue-50 to-white shadow-lg' 
                : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'}
            `}
            onClick={() => handleBrandingTypeChange(type as 'custom' | 'reference')}
          >
            <div className="flex items-center gap-6">
              <div className="text-4xl">{icon}</div>
              <div>
                <h4 className="text-xl font-medium mb-2">{title}</h4>
                <p className="text-gray-600">{description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {brandingType === 'reference' ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="space-y-4"
        >
          <label className="block text-sm font-medium text-gray-700">
            Reference Website URL
          </label>
          <Input
            placeholder="https://example.com"
            value={referenceURL}
            onChange={(e) => handleReferenceURLChange(e.target.value)}
            className="text-lg p-4 rounded-xl border-2"
          />
          <p className="text-sm text-gray-500">
            We'll analyze this website's design and create a similar style for your site
          </p>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="space-y-6"
        >
          {/* Logo Upload */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Upload Your Logo
            </label>
            <div className="flex items-center gap-4">
              {logo ? (
                <div className="relative">
                  <img
                    src={URL.createObjectURL(logo)}
                    alt="Logo preview"
                    className="h-20 w-20 object-contain rounded-lg border-2 border-gray-200"
                  />
                  <button
                    onClick={() => handleLogoChange(undefined)}
                    className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md text-red-500 hover:text-red-600"
                  >
                    <MdClose size={16} />
                  </button>
                </div>
              ) : (
                <label className="flex flex-col items-center justify-center w-20 h-20 
                               border-2 border-gray-300 border-dashed rounded-lg 
                               cursor-pointer hover:border-blue-500 hover:bg-gray-50">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <span className="text-2xl mb-2">+</span>
                    <span className="text-xs text-gray-500">Upload Logo</span>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleLogoChange(file);
                    }}
                  />
                </label>
              )}
            </div>
          </div>

          {/* Color Selection */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Brand Colors
            </label>
            <div className="flex flex-wrap gap-4">
              {brandColors.map((color, index) => (
                <div key={color.id} className="relative">
                  <input
                    type="color"
                    value={color.color}
                    onChange={(e) => {
                      const newColors = [...brandColors];
                      newColors[index].color = e.target.value;
                      handleColorChange(newColors);
                    }}
                    className="w-12 h-12 rounded-lg cursor-pointer border-2 border-gray-200"
                  />
                  <button
                    onClick={() => handleColorChange(brandColors.filter(c => c.id !== color.id))}
                    className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md text-red-500 hover:text-red-600"
                  >
                    <MdClose size={16} />
                  </button>
                </div>
              ))}
              {brandColors.length < 3 && (
                <button
                  onClick={() => handleColorChange([...brandColors, { id: Date.now().toString(), color: '#000000' }])}
                  className="w-12 h-12 flex items-center justify-center border-2 border-gray-300 
                           border-dashed rounded-lg hover:border-blue-500 hover:bg-gray-50"
                >
                  <span className="text-2xl">+</span>
                </button>
              )}
            </div>
            <p className="text-sm text-gray-500">
              Choose up to 3 colors for your brand (primary, secondary, and accent)
            </p>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export type { BrandingInfo };
export default BrandingSection; 