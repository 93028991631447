'use client'
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';

interface BookingSetupProps {
  pageId: string;
  pageSpecificInfo: any;
  onChange: (pageId: string, key: string, value: any) => void;
}

const BookingSetup: React.FC<BookingSetupProps> = ({
  pageId,
  pageSpecificInfo,
  onChange,
}) => {
  // Add local state to force re-renders
  const [localState, setLocalState] = useState(pageSpecificInfo[pageId] || {});

  // Sync local state with props
  useEffect(() => {
    setLocalState(pageSpecificInfo[pageId] || {});
  }, [pageSpecificInfo, pageId]);

  const handleOptionClick = (value: string) => {
    onChange(pageId, 'enableBooking', value);
    // Update local state immediately
    setLocalState((prev: any) => ({...prev, enableBooking: value}));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(pageId, 'bookingEmail', e.target.value);
    setLocalState((prev: any) => ({...prev, bookingEmail: e.target.value}));
  };

  const handleInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(pageId, 'bookingInstructions', e.target.value);
    setLocalState((prev: any) => ({...prev, bookingInstructions: e.target.value}));
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Enable Booking <span className="text-red-500">*</span>
        </label>
        <div className="space-y-2">
          {[
            { label: 'Yes, set up booking for me', value: 'yes' },
            { label: 'No, I\'ll set it up later', value: 'no' }
          ].map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              role="button"
              tabIndex={0}
              className={`p-4 border-2 rounded-xl cursor-pointer transition-all ${
                localState.enableBooking === option.value 
                  ? 'border-blue-500 bg-blue-50' 
                  : 'border-gray-200 hover:border-blue-200'
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {localState.enableBooking === 'yes' && (
          <>
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-4"
            >
              <label className="block text-sm font-medium text-gray-700">
                Email for Booking Calendar <span className="text-red-500">*</span>
              </label>
              <Input
                type="email"
                placeholder="Enter the email to receive booking notifications"
                value={localState.bookingEmail || ''}
                onChange={handleEmailChange}
                className="text-lg p-4 rounded-xl border-2"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="space-y-4"
            >
              <label className="block text-sm font-medium text-gray-700">
                Booking Instructions
              </label>
              <Input.TextArea
                placeholder="Add any special instructions or policies for booking appointments"
                value={localState.bookingInstructions || ''}
                onChange={handleInstructionsChange}
                className="text-lg p-4 rounded-xl border-2"
                rows={4}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BookingSetup; 