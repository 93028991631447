import { motion } from 'framer-motion';
import { MdClose } from 'react-icons/md';
import { Input, Button, Modal } from 'antd';

const OfferingInput: React.FC<{
    type: 'services' | 'products';
    offerings: Array<{
      id: string;
      name: string;
      description: string;
      image?: File;
      price?: string;
    }>;
    onChange: (offerings: Array<any>) => void;
  }> = ({ type, offerings, onChange }) => {
    const handleImageUpload = (id: string, file: File) => {
      console.log("Uploading image for offering:", id, file);
      const updatedOfferings = offerings.map(offering =>
        offering.id === id ? { ...offering, image: file } : offering
      );
      onChange(updatedOfferings);
    };
  
    const handleDelete = (index: number) => {
      Modal.confirm({
        title: `Delete ${type === 'services' ? 'Service' : 'Product'}`,
        content: 'Are you sure you want to delete this item?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          const updatedOfferings = [...offerings];
          updatedOfferings.splice(index, 1);
          onChange(updatedOfferings);
        },
      });
    };
  
    return (
      <div className="space-y-6">
        {offerings.map((offering, index) => (
          <motion.div
            key={offering.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-6 border-2 border-gray-200 rounded-xl relative"
          >
            <button
              onClick={() => handleDelete(index)}
              className="absolute top-4 right-4 p-2 text-gray-400 hover:text-red-500 
                       rounded-full hover:bg-red-50 transition-all"
            >
              <MdClose size={20} />
            </button>
            
            <div className="space-y-4">
              <Input
                placeholder={`${type === 'services' ? 'Service' : 'Product'} name`}
                value={offering.name}
                onChange={(e) => {
                  const updatedOfferings = [...offerings];
                  updatedOfferings[index].name = e.target.value;
                  onChange(updatedOfferings);
                }}
                className="text-xl p-4 rounded-xl border-2"
              />
              
              <Input.TextArea
                placeholder={`Describe this ${type === 'services' ? 'service' : 'product'}`}
                value={offering.description}
                onChange={(e) => {
                  const updatedOfferings = [...offerings];
                  updatedOfferings[index].description = e.target.value;
                  onChange(updatedOfferings);
                }}
                className="text-lg p-4 rounded-xl border-2"
                rows={3}
              />
  
              <Input
                placeholder="Price (optional)"
                value={offering.price}
                onChange={(e) => {
                  const updatedOfferings = [...offerings];
                  updatedOfferings[index].price = e.target.value;
                  onChange(updatedOfferings);
                }}
                className="text-lg p-4 rounded-xl border-2"
              />
  
              <div className="mt-4">
                {offering.image ? (
                  <div className="relative inline-block">
                    <img
                      src={URL.createObjectURL(offering.image)}
                      alt={offering.name}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const updatedOfferings = offerings.map(o =>
                          o.id === offering.id ? { ...o, image: undefined } : o
                        );
                        onChange(updatedOfferings);
                      }}
                      className="absolute -top-2 -right-2 p-1 bg-white rounded-full shadow-md
                               text-red-500 hover:text-red-600"
                    >
                      <MdClose size={16} />
                    </button>
                  </div>
                ) : (
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      id={`image-${offering.id}`}
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          console.log("File selected:", file);
                          handleImageUpload(offering.id, file);
                        }
                      }}
                    />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById(`image-${offering.id}`)?.click();
                      }}
                      className="border-2 border-dashed p-4"
                    >
                      Upload Image (optional)
                    </Button>
                  </div>
                )}
              </div>

              <Button 
                danger
                onClick={() => handleDelete(index)}
                className="w-full mt-4"
              >
                Delete {type === 'services' ? 'Service' : 'Product'}
              </Button>
            </div>
          </motion.div>
        ))}
  
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => {
            onChange([
              ...offerings,
              {
                id: `offering-${Date.now()}`,
                name: '',
                description: '',
              }
            ]);
          }}
          className="w-full p-6 border-2 border-dashed border-gray-300 rounded-xl
                     text-gray-500 hover:text-blue-500 hover:border-blue-500 
                     transition-all flex items-center justify-center space-x-2"
        >
          <span className="text-2xl">+</span>
          <span>Add {type === 'services' ? 'Service' : 'Product'}</span>
        </motion.button>
      </div>
    );
  };

export default OfferingInput;